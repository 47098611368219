.table {
    width: 1104px;
    height: 135px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.tableHeader {
    width: 100%;
    height: 27px;
    box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
}

.tableHeaderRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 27px;
    & div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 14px;
        font-weight: 700;
        &:last-child {
            border-right: none;
        }
    }
    & span {
        height: 14px;
        margin-left: 3px;
    }
}

.tableBody {
    width: 100%;
}

.tableBodyRow {
    width: 100%;
    height: 27px;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    & div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 27px;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &:nth-child(odd) {
        background-color: #F6F7F6;
    }
}