.daPagePerformance {
    width: 870px;
    height: 634px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.daPagePerformanceTitle {
    width: 827px;
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
}

.daPagePerformanceChart {
    width: 827px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    & div {
        display: flex;
        flex-direction: column;
        align-items: center;
        & p {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }
}
