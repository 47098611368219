.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #F5F4F4;
    overflow-y: auto;
}

.logo {
    margin-top: 30px;
    height: 117px;
}

.loginContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.loginBox {
    width: 511.52px;
    height: 592.28px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 416px;
    background-color: #FFFFFF;
}

.inputBox {
    width: 396px;
    height: 170px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.inputBox input {
    background-color: #FFF;
    border: none;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 700;
    color: #000;
    height: 100%;
    width: 100%;
}

.inputBox button {
    width: 100%;
    height: 54.71px;
    border: none;
    border-radius: 5px;
    background-color: #000000;
    color: #ffffff;
    font-weight: 800;
    font-size: 20px;
    cursor: pointer;
}

.idBox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 41.69px;
    background-color: #FFF;
    border: 1px solid #BDC2C7;
    border-radius: 5px;
    padding-left: 5px;
}

.pwBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 41.69px;
    background-color: #FFF;
    border: 1px solid #BDC2C7;
    border-radius: 5px;
    padding-left: 5px;
}

.pwMark {
    width: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.linkBox {
    display: flex;
    width: 100%;
    justify-content: center;
}

.linkBox a {
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    color: #3D3D3D;
    margin-left: 2px;
    margin-right: 2px;
}

.snsLogin {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    color: #8C8C8C;
    font-weight: 600;
    font-size: 11px;
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 150px;
        height: 1px;
        background-color: #BDC2C7;
    }

    &::before {
        left: 56px;
    }

    &::after {
        right: 56px;
    }
}

.termsBox {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    & a {
        font-size: 12px;
        font-weight: 500;
        color: #000;
    }
}