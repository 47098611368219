.myDataTxt {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
    width: 354px;
}

.myDataBox {
    width: 354px;
    height: 447px;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
    margin-bottom: 25px;
}

.myDataBoxTxt {
    font-size: 15px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
    margin-top: 15px;
}

.myDataBtn {
    width: 354px;
    height: 58px;
    margin-top: 50px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        & a {
            color: #FFF;
            font-size: 15px;
            font-weight: 800;
            text-decoration: none;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.myDataBoxConnected {
    width: 354px;
    height: 457px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.myDataBoxConnected::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
}

.myDataBoxConnected::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙 배경색 */
    border-radius: 5px; /* 트랙 경계선 둥글게 */
    display: none;
}

.myDataBoxConnected::-webkit-scrollbar-thumb {
    background: #00A0A0; /* 썸 배경색 */
    border-radius: 5px; /* 썸 경계선 둥글게 */
    height: 64px;
}

.myDataBoxConnected::-webkit-scrollbar-thumb:hover {
    background: #00A0A0; /* 썸 hover 시 배경색 */
}

.dataItem {
    width: 99%;
    height: 60px;
    background-color: #FFF;
    margin-bottom: 15px;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & svg {
        margin-left: 20px;
    }
}

.myDataTxtBox {
    width: 200px;
    margin-left: 20px;
}

.reportName {
    font-size: 14px;
    font-weight: 700;
}

.mediaName {
    font-size: 10px;
    font-weight: 600;
}