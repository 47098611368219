.resultMedia {
    width: 1132px;
    height: 665px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
}

.cardBox {
    width: 546px;
    height: 320px;
    border: 4px solid rgba(0, 160, 160, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.noDataBox {
    width: 546px;
    height: 320px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(244, 244, 244, 0.9);
        z-index: 1;
        border-radius: 10px;
    }
}

.noDataTxt {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    & p {
        color: rgba(0,0,0,0.7);
        text-decoration: underline;
        font-size: 12px;
        font-weight: 800;
    }
}

.cardTxt1 {
    font-size: 13px;
    font-weight: 600;
    width: 500px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.cardTxt2 {
    font-size: 13px;
    font-weight: 600;
    width: 500px;
    margin-bottom: 10px;
}

.mediaCard {
    width: 517px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.scoreCard {
    width: 163px;
    height: 84px;
    background-color: #FFF;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
}

.scoreCardTitle {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.scoreCardValue {
    font-size: 17px;
    font-weight: 600;
    color: #000;
}

.scoreCardChange {
    font-size: 11px;
    font-weight: 600;
    color: #848484;
}

.positive {
    color: #0048FF;
}

.negative {
    color: #FF0000;
}

.tableCol {
    width: 511px;
    height: 91px;
    display: flex;
    
    border: 1px solid rgba(0,0,0,0.05);
    
}

.tableBox {
    
}

.tableHead {
    width: 102px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0,0,0,0.05);
    // border-bottom: 1px solid rgba(0,0,0,0.05);
    // border-right: 1px solid rgba(0,0,0,0.05);
    // &:last-child {
    //     border-right: none;
    // }
    font-size: 13px;
    font-weight: 800;
}

.tableBody {
    width: 102px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0,0,0,0.05);
    // border-right: 1px solid rgba(0,0,0,0.05);
    background-color: #F6F7F6;
    // &:last-child {
    //     border-right: none;
    // }
    font-size: 13px;
    font-weight: 800;
}