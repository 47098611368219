.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.modalContent {
    width: 542px;
    height: 258px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & p {
        width: 438px;
        font-size: 15px;
        font-weight: 600;
        color: #000;
        margin-top: 15px;
        margin-right: 0px;
    }
    & input {
        width: 438px;
        height: 52px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        background-color: #FFF;
        padding-left: 10px;
        margin-top: 20px;
    }
    & input::placeholder {
        font-size: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
    }
}

.btnBox {
    width: 438px;
    height: 34px;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.closeBtn {
    width: 71px;
    height: 34px;
    color: #979797;
    background-color: #FFF;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-weight: 900;
    margin-right: 10px;
    cursor: pointer;
}

.unlinkBtnDisabled {
    width: 71px;
    height: 34px;
    color: #FFF;
    border: none;
    border-radius: 5px;
    background-color: rgba(0, 160, 160, 0.4);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-weight: 800;
    cursor: pointer;
}

.unlinkBtnActive {
    width: 71px;
    height: 34px;
    color: #FFF;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-weight: 800;
    cursor: pointer;
}

.modifyModal {
    position: absolute;
    top: 20px;
    right: 5px;
    width: 245px;
    height: 123px;
    z-index: 1;
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & p {
        width: 208px;
        font-size: 12px;
        font-weight: 900;
        color: #000;
        margin-top: 0px;
        margin-right: 0px;
    }
    & input {
        width: 208px;
        height: 33px;
        border: 1px solid #00A0A0;
        border-radius: 5px;
        background-color: #FFF;
        padding-left: 10px;
        margin-top: 10px;
    }
    & input::placeholder {
        font-size: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
    }
}

.modifyBtnBox {
    width: 208px;
    height: 33px;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

.modifyCloseBtn {
    width: 55px;
    height: 34px;
    color: #000;
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    margin-right: 10px;
    cursor: pointer;
}

.modifyChangeBtn {
    width: 55px;
    height: 34px;
    color: #FFF;
    background-color: #00A0A0;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
}