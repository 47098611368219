.reportCostDa {
    width: 870px;
    margin-right: 30px;
}

.trendBox {
    width: 870px;
    height: 800px;
    background-color: #FFF;
    border-radius: 5px;
    margin-bottom: 40px;
}

.avgBox {
    width: 870px;
    height: 561px;
    background-color: #FFF;
    border-radius: 5px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deviceBox {
    width: 870px;
    height: 618px;
    background-color: #FFF;
    border-radius: 5px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}