.nullBox {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    & p {
        color: rgba(0, 0, 0, 0.6);
        font-size: 16px;
        font-weight: 600;
    }
}