.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    width: 572px;
    height: 800px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalCloseBtn {
    width: 474px;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.modalText {
    width: 474px;
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
}

.ga4 {
    width: 476px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
}

.text1 {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 3px;
}

.text2 {
    font-size: 15px;
    font-weight: 600;
}

.ga4LoginBtn {
    width: 476px;
    height: 64px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        & p {
            position: absolute;
            width: 427px;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 5px;
        }
    }
}

.dataBox {
    width: 492px;
    height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.dataBox::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
}

.dataBox::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙 배경색 */
    border-radius: 5px; /* 트랙 경계선 둥글게 */
    display: none;
}

.dataBox::-webkit-scrollbar-thumb {
    background: #00A0A0; /* 썸 배경색 */
    border-radius: 5px; /* 썸 경계선 둥글게 */
    height: 64px;
}

.dataBox::-webkit-scrollbar-thumb:hover {
    background: #00A0A0; /* 썸 hover 시 배경색 */
}

.dataContainer {
    width: 476px;
    height: 60px;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

.dataName {
    width: 40%;
    font-size: 14px;
    font-weight: 800;
    margin-left: 15px;
}

.dataAccount {
    width: 110px;
    height: 24px;
    font-size: 11px;
    font-weight: 600;
    border-left: 1px solid #EAEAEA;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dataBtn {
    width: 83px;
    height: 26px;
    font-size: 11px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    color: #FFF;
}