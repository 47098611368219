.conversionTrend {
    width: 870px;
    height: 356px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.conversionTrendTitle {
    width: 827px;
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
}

.conversionTrendChart {
    width: 827px;
    height: 221px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 10px;
}

.conversionTrendSelect {
    width: 827px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-left: 50px;
    & select {
        width: 88px;
        height: 28px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 600;
        padding-left: 5px;
    }
}

.selectBox5 {
    border: 2px solid rgba(0, 176, 80, 0.6);
    color: #00B050;
}

.selectBox89 {
    border: 2px solid rgba(255, 180, 18, 0.6);
    color: #000;
}

.selectBox100 {
    border: 2px solid rgba(0, 114, 240, 0.6);
    color: #0072F0;
}
