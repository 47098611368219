.main {
    width: 1612px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.header {
    width: 100%;
    height: 84px;
    display: flex;
    justify-content: center;
    gap: 48px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.headerMenu {
    width: 389px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    & p {
        margin-top: 10px;
        font-size: 24px;
        font-weight: 900;
    }
}

.menuBottom {
    width: 389px;
    height: 7px;
    background-color: #FFF;
    border-radius: 5px;
}

.activeBottom {
    width: 389px;
    height: 7px;
    background-color: #00A0A0;
    border-radius: 5px;
}

.container {
    display: flex;
    // margin-left: 40px;
    width: 100%;
}

.selectBox {
    display: flex;
    margin-left: 40px;
    margin-right: 40px;
    width: 100%;
}