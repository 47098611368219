.predCondition {
    width: 1320px;
    height: 491px;
    background-color: #FFF;
    border-radius: 10px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & h3 {
        width: 1260px;
        margin-top: 30px;
    }
    & h5 {
        width: 1260px;
        margin-top: 20px;
    }
}

.conditionBox {
    width: 1172px;
    height: 331px;
    display: flex;
    gap: 42px;
    margin-top: 40px;
}

.adTarget {
    width: 300px;
    height: 331px;
}

.infoBox {
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-bottom: 20px;
    & p {
        font-size: 18px;
        font-weight: 800;
    }
}

.adCostChange {
    width: 830px;
    height: 331px;
    & h4 {
        margin-bottom: 20px;
    }
}

.radioGroup {
    width: 300px;
    height: 277px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    .radioOption {
        width: 200px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 800;
        color: #000;
        cursor: pointer;

        input[type="radio"] {
            display: none; // 기본 브라우저 스타일 숨기기
        }

        .radioCustom {
            width: 12px;
            height: 12px;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 50%;
            display: inline-block;
            position: relative;
            margin-right: 8px;

            &::after {
                content: "";
                width: 6px;
                height: 6px;
                background-color: #00A0A0; // 선택된 상태의 색상
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0; // 기본적으로 숨기기
                transition: opacity 0.2s ease;
            }
        }

        input[type="radio"]:checked + .radioCustom::after {
            opacity: 1; // 선택된 상태에서 표시
        }

        .radioLabel {
            color: #000;
        }
    }
}

.mediaBox {
    width: 830px;
    height: 277px;
    display: flex;
    justify-content: space-between;
}

.mediaContainer {
    width: 547px;
    height: 277px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.changeBox {
    width: 547px;
    height: 64px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    gap: 15px;
}

.noDataBox {
    width: 547px;
    height: 64px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0,0,0,0.05);
    border-radius: 10px;
    // box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    gap: 15px;
    position: relative;
    & div, p, span {
        color: rgba(0,0,0,0.25);
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(244, 244, 244, 0.8);
        // z-index: 1;
        border-radius: 10px;
    }
}

.noDataTxt {
    position: absolute;
    // z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    margin-left: 20px;
    & p {
        color: rgba(0,0,0,0.7);
        text-decoration: underline;
        font-size: 12px;
        font-weight: 800;
    }
}

.mediaImg {
    width: 40px;
    margin-left: 20px;
}

.mediaName {
    width: 103px;
    font-size: 16px;
    font-weight: 800;
}

.prevCost {
    width: 213px;
    height: 28px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    font-size: 15px;
    font-weight: 800;
}

.selectBox {
    width: 94px;
    height: 28px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    padding-left: 50px;
    appearance: none; // 기본 화살표 제거
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6"%3E%3Cpath d="M0 0l5 6 5-6z" fill="%2300A0A0"/%3E%3C/svg%3E'); // 커스텀 화살표
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px 12px;
    font-size: 15px;
    font-weight: 800;
    & option {
        font-size: 15px;
        font-weight: 800;
        border-radius: 5px;
    }
}

.totalBox {
    width: 270px;
    height: 277px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.totalBoxTxt {
    width: 236px;
    font-size: 18px;
    font-weight: 800;
    padding-left: 5px;
    margin-top: 20px;
}

.totalCost {
    width: 236px;
    height: 40px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 800;
    margin-top: 10px;
}

.predBtn {
    width: 236px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    color: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
}