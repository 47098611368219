.main {
    width: 974px;
    height: 700px;
    // margin-top: 5px;
    margin-left: 10px;
}

.btnBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.btnNum {
    width: 53px;
    height: 26px;
    margin-right: 5px;
    background-color: #FFF;
    border: 1px solid rgba(0,160,160,0.2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00A0A0;
    font-size: 12px;
    font-weight: 900;
}

.btnImgBox {
    display: flex;
    justify-content: space-between;
    width: 56px;
}

.btnImg {
    width: 26px;
    height: 26px;
    background-color: #FFF;
    border: 1px solid rgba(0,160,160,0.2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.imgBox {
    width: 100%;
    height: 754px;
    background-color: rgba(0,160,160,0.1);
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.imgContainer {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.imgTxt {
    position: absolute;
    width: 288px;
    height: 78px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.6);
    border-radius: 20px;
    & p {
        font-size: 24px;
        font-weight: 800;
        color: #00A0A0;
    }
}

.reportPreview {
    width: 100%;
    height: 758px;
    overflow-y: scroll;
    background-color: #FFF;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 10px;
    padding: 15px;
}

.reportPreview::-webkit-scrollbar {
    display: none;
}