.main-header {
    width: 100%;
    height: 54px;
    display: flex;
    background-color: #00A0A0;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
}

.header-text-box {
    width: 85%;
    height: 54px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.header-text-1 a{
    font-weight: bold;
    color: #FFFFFF;
    font-size: large;
    margin-left: 15px;
    height: 30px;
    // text-decoration: none;
    cursor: pointer;
    text-decoration: none;
}

.header-text-2 {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #007E7E;
    width: 133px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.user-box {
    display: flex;
    width: 150px;
    height: 54px;
    align-items: center;
    justify-content: center;
    border-color: #FFFFFF;
    margin-right: 15px;
    cursor: pointer;
}

.user-image {
    display: flex;
    margin-right: 10px;
}

.user-image svg{
    width: 30px;
    height: 30px;
}
.user-text {
    font-weight: 600;
    font-size: large;
    color: #FFFFFF;
    border-color: #FFFFFF;
    margin-left: 7px;
    cursor: pointer;
}
.dropdown {
    position: absolute;
    top: 20px;  
    right: 0px;
    width: 150px;
    height: 100px;
    z-index: 5;
}

.dropdown-ul {
    position: absolute;
    top: 40px;
    right: 0px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    list-style: none;
    padding: 10px;
    width: 150px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 5;
}


.dropdown-item {
    padding: 10px;
    cursor: pointer;
    color: #999;
}

.dropdown-item:hover {
    // border: ;
    border-radius: 15px;
    background-color: rgba(0,160,160,0.2);
    color: #00A0A0;
}

.dropdown-ul a {
    width: 100%;
    color: #999;
    
    // font-size: 12px;
    border: none;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.dropdown-ul a:hover {
    background-color: rgba(0,160,160,0.2);
}

.dropdown-ul a:hover{
    background-color: rgba(0,160,160,0.2);
    color: #00A0A0;
}

