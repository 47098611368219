.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    width: 580px;
    height: 845px;
    background-color: #FFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
}

.modalCloseBtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.modalTitle {
    width: 420px;
    border-bottom: 2px solid #00A0A0;
    margin-bottom: 25px;
    & p {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 10px;
    }
}

.mediaLinkBox {
    width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
}

.txtBox {
    width: 450px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.txtBoxTxt1 {
    font-size: 14px;
    font-weight: 800;
}

.txtBoxTxt2 {
    font-size: 14px;
    font-weight: 600;
    color: #C6C6C6;
}

.advMediaBox {
    width: 378px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.advMediaBoxTxt1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}

.advMediaBoxTxt2 {
    font-size: 12px;
    font-weight: 600;
}

.advLinkBox {
    width: 390px;
    height: 440px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.advLinkBox::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
}

.advLinkBox::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙 배경색 */
    border-radius: 5px; /* 트랙 경계선 둥글게 */
    display: none;
}

.advLinkBox::-webkit-scrollbar-thumb {
    background: #00A0A0; /* 썸 배경색 */
    border-radius: 5px; /* 썸 경계선 둥글게 */
    height: 64px;
}

.advLinkBox::-webkit-scrollbar-thumb:hover {
    background: #00A0A0; /* 썸 hover 시 배경색 */
}

.advLink {
    margin-bottom: 20px;
}

.advLinkSelect {
    width: 378px;
    height: 48px;
    background-color: #CCECEC;
    border-left: 5px solid #00A0A0;
    border-top: none;
    border-right: none;
    border-bottom: none;
    padding: 10px;
    appearance: none;
    background-image: url('../../../../assets/images/report/report-appearance.svg');
    background-repeat: no-repeat;
    background-position: right 20px center;
    display: flex;
    align-items: center;
    & span {
        color: #00A0A0;
        font-size: 15px;
        font-weight: 600;
    }
}

.dropdownList {
    width: 378px;
    background-color: #F4F5F6;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.dropdownItem {
    width: 100%;
    height: 18px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-left: 5px;
    &:hover {
        background-color: #CCECEC;
    }
}

.selectedItem {
    background-color: #CCECEC;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.checkboxContainer input {
    display: none; // 기본 체크박스 숨기기
}

.customCheckbox {
    width: 10px;
    height: 10px;
    border: 1px solid #000;
    border-radius: 50%; // 원형 체크박스
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.checkboxContainer input:checked + .customCheckbox::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    background-color: #00A0A0; // 체크된 상태의 색상
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.modalBtnBox {
    width: 378px;
    height: 55px;
    display: flex;
    justify-content: space-between;
}

.nextBtn {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}

.disabled {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
    background-color: rgba(0, 160, 160, 0.4);
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
}