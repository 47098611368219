.daAvg {
    width: 870px;
    // background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-bottom: 30px;
}

.daAvgTitle {
    width: 827px;
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 30px;
}

.chartLables {
    width: 730px;
    margin-bottom: 20px;
}

.daAvgChart {
    width: 827px;
    height: 201px;
    display: flex;
    flex-direction: column;
    align-items: center;
}