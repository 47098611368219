.predImpClick {
    width: 638px;
    height: 411px;
    border-radius: 10px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.predImpTxt {
    width: 580px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
    & p {
        font-size: 20px;
        font-weight: 800;
    }
}

.predImpLabels {
    width: 559px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.predImpChart {
    width: 580px;
    height: 320px;
    display: flex;
    // justify-content: flex-end;
    position: relative;
}

.lineBorder {
    position: absolute;
    width: 90px;
    height: 288px;
    border: 3px dashed #00A0A0;
    border-radius: 10px;
    background-color: rgba(0, 160, 160, 0.15);
    pointer-events: none;
//     margin-right: 54px;
    margin-top: 10px;
    right: 5px;
}