.mainBox {
    z-index: 0;
    width: 100%;
    height: 100%;
}

.mainHome {
    display: flex;
    width: 100%;
    height: 100%;
}