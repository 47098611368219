.daDeviceChart {
    width: 767px;
    height: 218px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.deviceGraphBox {
    width: 767px;
    height: 104px;
    display: flex;
    flex-wrap: wrap;
    gap: 29px;
}

.deviceGraphContainer {
    width: 369px;
    height: 104px;
}

.graphText {
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
    margin-bottom: 10px;
}

.graphGauge {
    width: 369px;
    height: 28px;
    border-radius: 10px;
    display: flex;
}

.gauge1 {
    height: 28px;
    background-color: #2E679C;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 2px solid #FFF;
}

.gauge3 {
    height: 28px;
    background-color: #FFFA76;
    border-right: 2px solid #FFF;
}

.gauge2 {
    height: 28px;
    background-color: #FF7676;
    border-right: 2px solid #FFF;
}

.gauge4 {
    height: 28px;
    background-color: #76FF7F;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.labelBox {
    width: 369px;
    height: 38px;
    display: flex;
    align-items: center;
    gap:23px
}

.squareLabel1 {
    position: relative;
    padding-left: 25px;
    font-size: 15px;
    font-weight: 600;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: #2E679C;
    }
}

.squareLabel3 {
    position: relative;
    padding-left: 25px;
    font-size: 15px;
    font-weight: 600;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: #FFFA76;
    }
}
.squareLabel2 {
    position: relative;
    padding-left: 25px;
    font-size: 15px;
    font-weight: 600;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: #FF7676;
    }
}
.squareLabel4 {
    position: relative;
    padding-left: 25px;
    font-size: 15px;
    font-weight: 600;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: #76FF7F;
    }
}