html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    // font-family: "Pretendard";
}

ul, pre {
    list-style: none;
    font-family: "Pretendard";
}

button,
select {
    margin: 0;
    padding: 0;
    font-family: "Pretendard";
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

input {
    margin: 0;
    padding: 0;
    font-family: "Pretendard";
    &:focus {
        outline: none;
    }
    &::placeholder {
        font-family: "Pretendard";
    }
}

html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

iframe {
    border: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    font-family: "Pretendard";
}

td,
th {
    padding: 0;
    font-family: "Pretendard";
}