.summary {
    width: 420px;
    height: 500px;
    border-radius: 10px;
    background-color: #fff;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 10px;
    padding-bottom: 7px;
}

.summaryTitle {
    width: 380px;
    height: 20px;
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.summaryTitleText {
    font-size: 16px;
    font-weight: 700;
}

.summaryTitleDate {
    font-size: 12px;
}

.summaryContent5 {
    width: 385px;
    height: 440px;
    margin-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
        width: 6px; /* 스크롤바 너비 */
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* 트랙 배경색 */
        border-radius: 5px; /* 트랙 경계선 둥글게 */
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background: #00B050; /* 썸 배경색 */
        border-radius: 5px; /* 썸 경계선 둥글게 */
        height: 64px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #00B050; /* 썸 hover 시 배경색 */
    }    
}

.summaryContent6 {
    width: 385px;
    height: 440px;
    margin-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
        width: 6px; /* 스크롤바 너비 */
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* 트랙 배경색 */
        border-radius: 5px; /* 트랙 경계선 둥글게 */
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background: #00B050; /* 썸 배경색 */
        border-radius: 5px; /* 썸 경계선 둥글게 */
        height: 64px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #00B050; /* 썸 hover 시 배경색 */
    }    
}

.summaryContent7 {
    width: 385px;
    height: 440px;
    margin-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
        width: 6px; /* 스크롤바 너비 */
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* 트랙 배경색 */
        border-radius: 5px; /* 트랙 경계선 둥글게 */
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background: #FFB412; /* 썸 배경색 */
        border-radius: 5px; /* 썸 경계선 둥글게 */
        height: 64px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #FFB412; /* 썸 hover 시 배경색 */
    }
}

.summaryContent89 {
    width: 385px;
    height: 440px;
    margin-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
        width: 6px; /* 스크롤바 너비 */
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* 트랙 배경색 */
        border-radius: 5px; /* 트랙 경계선 둥글게 */
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background: #FFB412; /* 썸 배경색 */
        border-radius: 5px; /* 썸 경계선 둥글게 */
        height: 64px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #FFB412; /* 썸 hover 시 배경색 */
    }
}

.summaryContent100 {
    width: 385px;
    height: 440px;
    margin-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.summaryContent100::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
}

.summaryContent100::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙 배경색 */
    border-radius: 5px; /* 트랙 경계선 둥글게 */
    display: none;
}

.summaryContent100::-webkit-scrollbar-thumb {
    background: #0072F0; /* 썸 배경색 */
    border-radius: 5px; /* 썸 경계선 둥글게 */
    height: 64px;
}

.summaryContent100::-webkit-scrollbar-thumb:hover {
    background: #0072F0; /* 썸 hover 시 배경색 */
}

.summaryContentText {
    width: 370px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
}