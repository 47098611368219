.detailedAnalysis {
    width: 870px;
    height: 582px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
}

.detailedAnalysisTitle {
    width: 827px;
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
}

.detailedAnalysisButton {
    width: 844px;
    height: 36px;
    margin-top: 20px;
    display: flex;
    gap: 2px;
    & button {
        width: 131px;
        height: 36px;
        border-radius: 5px 5px 0px 0px;
        font-size: 15px;
        font-weight: 700;
        z-index: 1;
    }
}

.selected5 {
    border: 1px solid #00B050;
    border-bottom: 1px solid #FFF;
    background-color: #FFF;
    color: #000;
}

.selected89 {
    border: 1px solid #FFB412;
    border-bottom: 1px solid #FFF;
    background-color: #FFF;
    color: #000;
}

.selected100 {
    border: 1px solid #0072F0;
    border-bottom: 1px solid #FFF;
    background-color: #FFF;
    color: #000;
}

.noSelected5 {
    border: 1px solid #00B050;
    background-color: #00B050;
    color: #FFF;
}

.noSelected89 {
    border: 1px solid #FFB412;
    background-color: #FFB412;
    color: #000;
}

.noSelected100 {
    border: 1px solid #0072F0;
    background-color: #0072F0;
    color: #FFF;
}

.detailedAnalysisTable5 {
    width: 844px;
    height: 461px;
    border: 1px solid #00B050;
    border-radius: 0px 10px 10px 10px;
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detailedAnalysisTable89 {
    width: 844px;
    height: 461px;
    border: 1px solid #FFB412;
    border-radius: 0px 10px 10px 10px;
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detailedAnalysisTable100 {
    width: 844px;
    height: 461px;
    border: 1px solid #0072F0;
    border-radius: 0px 10px 10px 10px;
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dataSelectBox {
    width: 804px;
    height: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.dataSelectButton5 {
    height: 23px;
    display: flex;
    gap: 5px;
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 8px;
        padding-right: 8px;
        border: 2px solid rgba(0, 176, 80, 0.2);
        border-radius: 3px;
        background-color: #FFF;
        color: #00B050;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        &.active {
            background-color: #00B050;
            color: #FFF;
        }
    }
}

.dataSelectButton89 {
    height: 23px;
    display: flex;
    gap: 5px;
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 8px;
        padding-right: 8px;
        border: 2px solid rgba(255, 180, 18, 0.2);
        border-radius: 3px;
        background-color: #FFF;
        color: #000;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        &.active {
            background-color: #FFB412;
            color: #000;
        }
    }
}

.dataSelectButton100 {
    height: 23px;
    display: flex;
    gap: 5px;
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 8px;
        padding-right: 8px;
        border: 2px solid rgba(0, 114, 240, 0.2);
        border-radius: 3px;
        background-color: #FFF;
        color: #0072F0;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        &.active {
            background-color: #0072F0;
            color: #FFF;
        }
    }
}

.excelDownload {
    width: 120px;
    height: 23px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}