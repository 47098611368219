.reportSa {
    position: fixed;
    margin-top: 54px;
    margin-left: 300px;
    width: calc(100% - 300px);
    height: calc(100% - 54px);
    background-color: #F5F4F4;
    overflow-x: scroll;
    overflow-y: scroll;
}

.reportSa::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
    height: 6px;
}

.reportSa::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙 배경색 */
    border-radius: 5px; /* 트랙 경계선 둥글게 */
    display: none;
}

.reportSa::-webkit-scrollbar-thumb {
    background: #00A0A0; /* 썸 배경색 */
    border-radius: 5px; /* 썸 경계선 둥글게 */
    height: 64px;
}

.reportSa::-webkit-scrollbar-thumb:hover {
    background: #00A0A0; /* 썸 hover 시 배경색 */
}

.reportSaHeader {
    width: 1612px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    position: relative;
}

.reportSaHeaderTitle {
    width: 1210px;
    font-size: 20px;
    font-weight: 700;
}

.reportSaHeaderIcon {
    
    display: flex;
    gap: 10px;
}

.reportSaButtonContainer {
    width: 1612px;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.reportSaButtonBox {
    width: 1320px;
    display: flex;
    justify-content: center;
    gap: 30px;
    & button {
        width: 420px;
        height: 51px;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 700;
    }
}

.reportNaverSaButton {
    background-color: #FFF;
    border: 4px solid rgba(0, 176, 80, 0.2);
    color: #00B050;
}

.reportKakaoSaButton {
    background-color: #FFF;
    border: 4px solid rgba(255, 180, 18, 0.2);
    color: #000;
}

.reportGoogleSaButton {
    background-color: #FFF;
    border: 4px solid rgba(0, 114, 240, 0.2);
    color: #0072F0;
}

.reportNaverSaButtonActive {
    border: none;
    background-color: #00B050;
    color: #FFF;
}

.reportKakaoSaButtonActive {
    border: none;
    background-color: #FFB412;
    color: #000;
}

.reportGoogleSaButtonActive {
    border: none;
    background-color: #0072F0;
    color: #FFF;
}

.reportSaContent {
    width: 1612px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.reportSaGoal {
    width: 420px;
    height: 207px;
    background-color: #FFF;
    border-radius: 10px;
}