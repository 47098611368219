.targetResult {
    width: 420px;
    height: 207px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.targetResultTitleTxt {
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

.targetResultTitleBtn {
    width: 101px;
    height: 30px;
    border-radius: 5px;
    border: none;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    cursor: pointer;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.targetResultTitle5 {
    width: 389px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    & button {
        background-color: #00B050;
    }
}

.targetResultTitle6 {
    width: 389px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    & button {
        background-color: #00B050;
    }
}

.targetResultTitle7 {
    width: 389px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    & button {
        background-color: #FFB412;
    }
}

.targetResultTitle89 {
    width: 389px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    & button {
        background-color: #FFB412;
    }
}

.targetResultTitle100 {
    width: 389px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    & button {
        background-color: #0072F0;
    }
}

.targetResultTitle200 {
    width: 389px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    & button {
        background-color: #2E679C;
    }
}

.targetResultContentTxt {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-bottom: 15px;
}

.targetResultContentBtn {
    width: 130px;
    height: 30px;
    border-radius: 5px;
    border: none;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    cursor: pointer;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.targetResultContent5 {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & button {
        background-color: #00B050;
    }
}

.targetResultContent6 {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & button {
        background-color: #00B050;
    }
}

.targetResultContent7 {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & button {
        background-color: #FFB412;
    }
}

.targetResultContent89 {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & button {
        background-color: #FFB412;
    }
}

.targetResultContent100 {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & button {
        background-color: #0072F0;
    }
}

.targetResultContent200 {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & button {
        background-color: #2E679C;
    }
}

.targetInput {
    width: 420px;
    height: 207px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.targetInputTitle {
    width: 389px;
    height: 30px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & p {

        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
}

.targetInputCancel5 {
    width: 46px;
    height: 30px;
    border: 1px solid #00B050;
    border-radius: 5px;
    color: #00B050;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    margin-right: 10px;
}

.targetInputCancel6 {
    width: 46px;
    height: 30px;
    border: 1px solid #00B050;
    border-radius: 5px;
    color: #00B050;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    margin-right: 10px;
}

.targetInputCancel7 {
    width: 46px;
    height: 30px;
    border: 1px solid #FFB412;
    border-radius: 5px;
    color: #FFB412;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    margin-right: 10px;
}

.targetInputCancel89 {
    width: 46px;
    height: 30px;
    border: 1px solid #FFB412;
    border-radius: 5px;
    color: #FFB412;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    margin-right: 10px;
}

.targetInputCancel100 {
    width: 46px;
    height: 30px;
    border: 1px solid #0072F0;
    border-radius: 5px;
    color: #0072F0;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    margin-right: 10px;
}

.targetInputCancel200 {
    width: 46px;
    height: 30px;
    border: 1px solid #2E679C;
    border-radius: 5px;
    color: #2E679C;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    margin-right: 10px;
}

.targetInputChange5 {
    width: 72px;
    height: 30px;
    border: none;
    border-radius: 5px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #00B050;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.targetInputChange6 {
    width: 72px;
    height: 30px;
    border: none;
    border-radius: 5px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #00B050;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.targetInputChange7 {
    width: 72px;
    height: 30px;
    border: none;
    border-radius: 5px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #FFB412;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.targetInputChange89 {
    width: 72px;
    height: 30px;
    border: none;
    border-radius: 5px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #FFB412;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.targetInputChange100 {
    width: 72px;
    height: 30px;
    border: none;
    border-radius: 5px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #0072F0;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.targetInputChange200 {
    width: 72px;
    height: 30px;
    border: none;
    border-radius: 5px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #2E679C;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.targetInputContent {
    width: 389px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
}

.targetInputContentItem {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    & p {
        width: 72px;

        font-size: 14px;
        font-weight: 600;
        color: #000;
        display: flex;
        justify-content: flex-end;
        margin-right: 30px;
    }
    & input {
        width: 174px;
        height: 22px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        padding: 0 5px;

        font-size: 11px;
        font-weight: 600;
        color: #000;
        &::placeholder {
    
            font-size: 11px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.2);
        }
    }
}

.targetResultGaugeContent {
    width: 389px;
    height: 101px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    gap: 19px;
}

.targetResultGaugeItem {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
}

.targetResultGaugeItemTitle {
    width: 61px;
    height: 27px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.targetGaugeBox {
    width: 240px;
    height: 27px;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 10px;
}

.targetGauge5 {
    width: 219px;
    height: 17px;
    border: 1px solid #00B050;
    border-radius: 10px;
}

.targetGauge6 {
    width: 219px;
    height: 17px;
    border: 1px solid #00B050;
    border-radius: 10px;
}

.targetGauge7 {
    width: 219px;
    height: 17px;
    border: 1px solid #FFB412;
    border-radius: 10px;
}

.targetGauge89 {
    width: 219px;
    height: 17px;
    border: 1px solid #FFB412;
    border-radius: 10px;
}

.targetGauge100 {
    width: 219px;
    height: 17px;
    border: 1px solid #0072F0;
    border-radius: 10px;
}

.targetGauge200 {
    width: 219px;
    height: 17px;
    border: 1px solid #2E679C;
    border-radius: 10px;
}

.targetGaugeBar5 {
    max-width: 219px;
    height: 17px;
    position: absolute;
    background-color: #00B050;
    border-radius: 10px;
}

.targetGaugeBar6 {
    max-width: 219px;
    height: 17px;
    position: absolute;
    background-color: #00B050;
    border-radius: 10px;
}

.targetGaugeBar7 {
    max-width: 219px;
    height: 17px;
    position: absolute;
    background-color: #FFB412;
    border-radius: 10px;
}

.targetGaugeBar89 {
    max-width: 219px;
    height: 17px;
    position: absolute;
    background-color: #FFB412;
    border-radius: 10px;
}

.targetGaugeBar100 {
    max-width: 219px;
    height: 17px;
    position: absolute;
    background-color: #0072F0;
    border-radius: 10px;
}

.targetGaugeBar200 {
    max-width: 219px;
    height: 17px;
    position: absolute;
    background-color: #2E679C;
    border-radius: 10px;
}

.targetResultGaugeItemPercent {
    width: 42px;
    height: 27px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    display: flex;
    align-items: center;
}

.targetGaugeValue {
    width: 240px;
    display: flex;
    position: relative;
    justify-content: space-between;
}

.targetGaugeValueZero {
    width: 10px;
    font-size: 11px;
    font-weight: 600;
    color: #000;
}

.targetGaugeValueText5 {
    font-size: 11px;
    font-weight: 700;
    color: #00B050;
}

.targetGaugeValueText6 {
    font-size: 11px;
    font-weight: 700;
    color: #00B050;
}

.targetGaugeValueText7 {
    font-size: 11px;
    font-weight: 700;
    color: #FFB412;
}

.targetGaugeValueText89 {
    font-size: 11px;
    font-weight: 700;
    color: #FFB412;
}

.targetGaugeValueText100 {
    font-size: 11px;
    font-weight: 700;
    color: #0072F0;
}

.targetGaugeValueText200 {
    max-width: 150px;
    font-size: 11px;
    font-weight: 700;
    color: #2E679C;
}

.targetGaugeValueTarget {
    font-size: 11px;
    font-weight: 600;
    color: #000;
}