.prevWeekly {
    width: 1320px;
    height: 652px;
    background-color: #FFF;
    border-radius: 10px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.prevWeeklyChart {
    width: 1104px;
    height: 337px;
    display: flex;
    gap: 99px;

}

.prevWeeklyPie {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.prevWeeklyBarLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.chartTxt {
    font-size: 20px;
    font-weight: 600;

}