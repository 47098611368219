.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    width: 389px;
    height: 249px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & svg {
        margin-bottom: 10px;
    }
    & p {
        font-size: 11px;
        font-weight: 600;
    }
    & button {
        width: 172px;
        height: 35px;
        color: #FFF;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        font-size: 12px;
        font-weight: 800;
        margin-top: 20px;
    }
}