.daGroupSum {
    width: 870px;
    height: 862px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.daGroupSumTitle {
    width: 827px;
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
}

.daGroupSumSelect6 {
    width: 827px;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    & select {
        width: 84px;
        height: 28px;
        border: 2px solid rgba(0, 176, 80, 0.6);
        border-radius: 5px;
        color: #00B050;
        font-size: 14px;
        font-weight: 600;
        padding-left: 10px;
        margin-left: 10px;
    }
}

.daGroupSumSelect7 {
    width: 827px;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    & select {
        width: 84px;
        height: 28px;
        border: 2px solid rgba(255, 180, 18, 0.6);
        border-radius: 5px;
        color: #000;
        font-size: 14px;
        font-weight: 600;
        padding-left: 10px;
        margin-left: 10px;
    }
}

.daGroupSumSelect100 {
    width: 827px;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    & select {
        width: 84px;
        height: 28px;
        border: 2px solid rgba(0, 114, 240, 0.6);
        border-radius: 5px;
        color: #0072F0;
        font-size: 14px;
        font-weight: 600;
        padding-left: 10px;
        margin-left: 10px;
    }
}

.daGroupSumChart {
    width: 827px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    & div {
        display: flex;
        flex-direction: column;
        align-items: center;
        & p {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }
}

