.modalContent {
    width: 457px;
    height: 630px;
    background-color: #F5F4F4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    margin-left: 620px;
    z-index: 1;
}

.modalHeader {
    width: 100%;
    height: 50px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 7px 9px 7px 9px;
}

.date {
    width: 156px;
    height: 36px;
    background-color: rgba(0, 160, 160, 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
}

.hipen {
    color: #00A0A0;
    font-size: 20px;
    font-weight: 700;
}

.dateChangeBtn {
    width: 91px;
    height: 36px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
        background-color: #00A0A0;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
    }
}

.modalBody {
    width: 100%;
    height: 571px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.calendar {
    width: 457px;
    height: 484px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-datepicker {
    width: 457px;
    height: 484px;
    border: none !important;
    font-family: "Pretendard" !important;
}

.react-datepicker__month-container {
    width: 100%;
    height: 100%;
}

.react-datepicker__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF !important;
    padding: 0 !important;
    border: none !important;
    border-radius: 10px !important;
}

.customHeaderContainer {
    margin-top: 12px;
    width: 366px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.customHeaderBox {
    margin-top: 12px;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    & button {
        border: none;
        background-color: #FFF;
        font-size: 15px;
        font-weight: 700;
        width: 20px;
        font-family: "Pretendard" !important;
        cursor: pointer;
    }
    & select {
        &::-webkit-scrollbar {
            width: 4px; /* 스크롤바 너비 */
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1; /* 트랙 배경색 */
            border-radius: 5px; /* 트랙 경계선 둥글게 */
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            background: #00A0A0; /* 썸 배경색 */
            border-radius: 5px; /* 썸 경계선 둥글게 */
            height: 20px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #00A0A0; /* 썸 hover 시 배경색 */
        }
    }
}

.year {
    border: none;
    font-size: 15px;
    font-weight: 700;
}

.month {
    font-size: 15px;
    font-weight: 700;
}

.weekdayContainer {
    width: 366px;
    display: flex;
    justify-content: space-between;
}

.weekday {
    font-size: 15px;
    font-weight: 700;
    position: relative;
    width: 35px;
    height: 18px;
    text-align: center;
    margin-top: 40px;
}

.weekday::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%; /* 요소의 중간에서 시작 */
    transform: translateX(-50%);
    width: 15px; /* 중간에 적용될 선의 길이 */
    border-bottom: 1px solid #000;
}

.react-datepicker__day-names {
    display: none;
}

.react-datepicker__month {
    width: 457px;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-datepicker__week {
    width: 366px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    & div {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 700;
    }
}

.selected-date {
    background-color: #00A0A0 !important;
    color: white !important;
    border-radius: 50% !important;
}

.react-datepicker__day:hover {
    background-color: #add8e6 !important; /* hover 시 배경색 */
    color: black; /* hover 시 글자 색 */
    border-radius: 50% !important;
    cursor: pointer;
}

.react-datepicker__day--keyboard-selected {
    // background-color: #FFF;
    // color: #FFF !important;
    border-radius: 50% !important;
}

.react-datepicker__day--outside-month {
    cursor: default;
    visibility: hidden;
}

.buttonContainer {
    width: 366px;
    height: 37px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        color: white;
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
    }
}