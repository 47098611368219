.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent2 {
    width: 572px;
    height: 823px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalContent {
    width: 572px;
    height: 823px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalCloseBtn {
    width: 474px;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.modalText {
    width: 474px;
    border-bottom: 1px solid rgba(0, 0, 0);
    padding-bottom: 10px;
}

.text1 {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 3px;
}

.text2 {
    font-size: 15px;
    font-weight: 600;
}

.listBox {
    width: 370px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    margin-top: 30px;
}

.list {
    width: 108px;
    height: 108px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

    & svg {
        width: 50px;
        height: 50px;
    }

    & p {
        font-size: 13px;
        font-weight: 600;
        margin-top: 10px;
    }
}

.ga4 {
    width: 476px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
}

.socialLoginBtn {
    width: 476px;
    height: 45px;
    margin-top: 40px;

    & button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
    }
}

.ga4LoginBtn {
    width: 476px;
    height: 64px;

    & button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        & p {
            position: absolute;
            width: 427px;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 5px;
        }
    }
}

.naverSA {
    width: 430px;
    height: 360px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.naverInputBox {
    width: 100%;
    height: 81px;
    border-radius: 5px;
    background-color: #F4F6F8;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & p {
        height: 18px;
        font-size: 15px;
        font-weight: 600;
        color: #000;
        margin-left: 20px;
        margin-bottom: 5px;
    }

    & input {
        width: 351px;
        height: 32px;
        border-radius: 5px;
        border-color: rgba(0, 0, 0, 0.5);
        margin-left: 20px;
        padding-left: 10px;
    }

    & input::placeholder {
        font-size: 10px;
        font-weight: 600;
    }
}

.rowContaioner {
    position: absolute;
    width: 430px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 640px;


    .cantDataLinkBox {

        width: 314px;
        height: 55px;
        border-radius: 10px;
        background-color: #CCECEC;
        font-weight: 500;


        .cantDataLink {
            width: 314px;
            height: 55px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.naverLoginBtn {
    width: 430px;
    display: flex;
    justify-content: flex-end;
    margin-top: 180px;


    & button {
        height: 45px;
        width: 135px;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
    }

}

.guideBtn {
    width: 430px;
    height: 46px;
    margin-top: 20px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        & p {
            position: absolute;
            width: 390px;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 5px;
        }
    }
}