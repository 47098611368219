.campaignGauge {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.campaignGaugeTitle {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 65px;
}

.campaignGaugeText1 {
    font-size: 13px;
    font-weight: 700;
    color: #000;
}

.campaignGaugeText2 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

