.resultScoreCard {
    width: 1121px;
    height: 177px;
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    gap: 20px;
}

.totalScore {
    width: 700px;
    height: 177px;
    border-right: 4px solid rgba(0, 160, 160, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.scoreTxt {
    font-size: 20px;
    font-weight: 600;
}

.predScoreTxt {
    font-size: 20px;
    font-weight: 600;
}

.totalScoreCard {
    width: 700px;
    height: 124px;
    display: flex;
    gap: 9px;
    
}

.scoreCard {
    width: 128px;
    height: 124px;
    background-color: #FFF;
    border-radius: 10px;
    border: 4px solid rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scoreCardTitle {
    font-size: 13px;
    font-weight: 600;
    color: #000;
}

.scoreCardValue {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.scoreCardChange {
    font-size: 11px;
    font-weight: 600;
    color: #848484;
}

.positive {
    color: #0048FF;
}

.negative {
    color: #FF0000;
}

.predScore {
    width: 404px;
    height: 177px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.predScoreCard {
    width: 404px;
    height: 124px;
    display: flex;
    gap: 9px;
}

.predCard {
    width: 128px;
    height: 124px;
    background-color: #FFF;
    border-radius: 10px;
    border: 4px solid #00A0A0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}