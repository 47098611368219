.login {
    width: 398px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.googleBtn {
    width: 100%;
    height: 48px;
    background-color: #FFFFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #BDC2C7;
    border-radius: 6px;
    margin-bottom: 5px;
    box-shadow: 0px 2px 4px 0px rgba($color: #000000, $alpha: 0.25);
    & p {
        padding-right: 10px;
        font-size: 14px;
        font-weight: 700;
    }
}

.naverBtn {
    width: 100%;
    height: 48px;
    background-color: #03C75A;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 6px;
    margin-bottom: 5px;
    box-shadow: 0px 2px 4px 0px rgba($color: #000000, $alpha: 0.25);
    & p {
        padding-right: 10px;
        font-size: 14px;
        font-weight: 700;
        color: #FFF;
    }
}

.kakaoBtn {
    width: 100%;
    height: 48px;
    background-color: #FEE500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 6px;
    margin-bottom: 5px;
    box-shadow: 0px 2px 4px 0px rgba($color: #000000, $alpha: 0.25);
    & p {
        padding-right: 10px;
        font-size: 14px;
        font-weight: 700;
    }
}