.main {
    margin-top: 15px;
    margin-left: 30px;
}

.previewTxt {
    font-size: 20px;
    font-weight: 700;
}

.previewBox {
    width: 950px;
    height: 170px;
    margin-top: 10px;
    padding: 10px;
    gap: 20px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    mask-image: linear-gradient(to right, transparent, black 2%, black 98%, transparent); /* 그라데이션 마스크 적용 */
    // background: linear-gradient(to right, rgba(255, 255, 255, 0), #F5F4F4 80%);
}

.previewBox::-webkit-scrollbar {
    display: none;
}

.previewControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1021px;
}

.scrollButton {
    height: 147px;
    border: none;
    cursor: pointer;
    // position: sticky;
    // margin-left: 1021px;
    // right: 0;
    display: flex;
    align-items: center;
}

.linear {
    width: 139px;
    height: 150px;
    position: sticky;
    // margin-left: 900px;
    right: 0;
    top: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #F5F4F4 80%);
}
