.mainBox {
    width: 100%;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
}

.mainBox::-webkit-scrollbar {
    display: none;
}

.leftBox {
    width: 60%;
    min-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.adfInfo1 {
    width: 80%;
    margin-top: 50px;
    margin-bottom: 50px;
    & div {
        display: flex;
        align-items: flex-end;
        & h3 {
            padding-bottom: 10px;
            margin-left: 10px;
        }
    }
    & h3 {
        font-size: 32px;
    }
}

.adfInfo2 {
    width: 80%;
    margin-bottom: 20px;
    display: flex;
    & div {
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    & h3 {
        font-size: 24px;
    }
    & h5 {
        font-size: 15px;
    }
}

.adfInfo3 {
    width: 80%;
    margin-bottom: 30px;
    display: flex;
    & div {
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    & h3 {
        font-size: 24px;
    }
    & h5 {
        font-size: 15px;
    }
}

.adfInfo4 {
    width: 80%;
    display: flex;
    & div {
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    & h3 {
        font-size: 24px;
    }
    & h5 {
        font-size: 15px;
    }
}

.signBox {
    width: 30%;
    max-width: 585px;
    min-width: 460px;
    display: flex;
    flex-direction: column; 
    align-items: center;
}

.txtBox {
    margin-top: 120px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleText {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 40px;
}

.txt1 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 40px;
}

.txt2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.txt3 {
    font-size: 14px;
    font-weight: 600;
}

.signFormBox {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
}

.advBox {
    width: 100%;
    height: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

.advInput {
    width: 100%;
    height: 40px;
    & input {
        width: 100%;
        height: 40px;
        padding-left: 10px;
        border: 1px solid #BDC2C7;
        border-radius: 5px;
    }
    & input::placeholder {
        font-size: 13px;
        font-weight: 600;
    }
    & input:focus {
        border: 1px solid #BDC2C7;
    }
}

.advSelect {
    width: 80%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    & select {
        width: 100%;
        height: 100%;
        border: 1px solid #BDC2C7;
        border-radius: 5px;
        color: #8C8C8C;
        & option {
            color: #8C8C8C;
            
        }
    }
}

.agreeBox {
    width: 80%;
    margin-bottom: 20px;
}

.totalAgree {
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-left: 5px;
    & label {
        margin-left: 8px;
    }
}

.termsBox {
    width: 100%;
    height: 111px;
    border: 1px solid #BDC2C7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.termsDiv {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & p {
        width: 50px;
        font-size: 14px;
        font-weight: 600;
    }
    & label {
        width: 270px;
        font-size: 14px;
        font-weight: 600;
    }
    & button {
        border: none;
        background-color: #FFF;
    }
}

.custom-checkbox {
    width: 14px;
    height: 14px;
    border: 1px solid #BDC2C7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 8px;
    margin-left: 10px;
    position: relative;
}

.custom-checkbox.checked::before {
    content: '';
    width: 6px;
    height: 6px;
    background-color: black;
    border-radius: 50%;
    position: absolute;
}

.signUpBox {
    width: 80%;
    height: 45px;
    & button {
        width: 100%;
        height: 100%;
        background-color: #000;
        color: #FFF;
        border: none;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 800;
        box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
    }
}

.modal {
    width: 100%;
    display: flex;
}

.overlay {
    position: fixed;
    inset: 0px;
}

.modalBox {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;
    margin-top: 50px;
    & h2 {
        font-size: 26px;
        font-weight: 800;
    }
}

.modalContent {
    width: 100%;
    height: 652px;
    overflow-y: scroll;
    box-shadow: inset 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
    margin-top: 20px;
    & p {
        margin-top: 30px;
        margin-left: 15px;
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: 13px;
        font-weight: 800;
    }
}

.modalContent::-webkit-scrollbar {
    width: 10px; /* 스크롤바 너비 */
}

.modalContent::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙 배경색 */
    border-radius: 10px; /* 트랙 경계선 둥글게 */
    display: none;
}

.modalContent::-webkit-scrollbar-thumb {
    background: #E9E9E9; /* 썸 배경색 */
    border-radius: 10px; /* 썸 경계선 둥글게 */
    height: 164px;
}

.modalContent::-webkit-scrollbar-thumb:hover {
    background: #E9E9E9; /* 썸 hover 시 배경색 */
}

.modalBtn {
    width: 100%;
    height: 63px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    & button {
        width: 277px;
        border: none;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
        font-size: 20px;
        font-weight: 800;
    }
    & button:first-child {
        background-color: #FFF;
        color: #000;
    }
    & button:last-child {
        background-color: #000;
        color: #FFF;
    }
}