.box {
    width: 462px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pwfindTxt1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 7px;
}

.pwfindTxt2 {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
    margin-bottom: 15px;
}

.inputBox {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    & input {
        width: 305px;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px;
        padding-left: 5px;
        font-size: 14px;
        font-weight: 600;
        color: #000;
    }
    & input::placeholder {
        font-size: 14px;
        font-weight: 600;
        color: rgba(0,0,0,0.5);
    }
}

.sendBtn {
    width: 150px;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}

.btnBox {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
}

.closeBtn {
    width: 228px;
    height: 100%;
    border: none;
    border-radius: 5px;
    background-color: rgba(0,160,160,0.2);
    color: #00A0A0;
    font-size: 20px;
    font-weight: 600;
}

.okBtn {
    width: 228px;
    height: 100%;
    border: none;
    border-radius: 5px;
    background-color: rgba(0,160,160,0.2);
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}

.okActiveBtn {
    width: 228px;
    height: 100%;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}

.certBox {
    width: 100%;
    margin-bottom: 20px;
}

.certTxt1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 7px;
}

.certTxt2 {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
    margin-bottom: 15px;
}

.certInput {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    & input {
        width: 305px;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px;
        padding-left: 5px;
        font-size: 14px;
        font-weight: 600;
        color: #000;
    }
    & input::placeholder {
        font-size: 14px;
        font-weight: 600;
        color: rgba(0,0,0,0.5);
    }
}


.certBtn {
    width: 150px;
    border: none;
    border-radius: 5px;
    background-color: rgba(0,160,160,0.2);
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}

.reCertBox {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.reCertTxt1 {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
}

.reCertTxt2 {
    width: 40px;
    font-size: 14px;
    font-weight: 600;
    color: #3C90FF;
    margin-left: 15px;
}

.reCertTxt3 {
    font-size: 14px;
    font-weight: 600;
    color: #3C90FF;
    margin-left: 110px;
    border-bottom: 1px solid #3C90FF;
    cursor: pointer;
}

.reCertTxt4 {
    font-size: 14px;
    font-weight: 600;
    color: #3C90FF;
    margin-left: 5px;
}

.certInfo {
    height: 91px;
    border-left: 3px solid #00A0A0;
}

.certInfoTxt1 {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
    margin-left: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
}

.certInfoTxt2 {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
    margin-left: 10px;
}