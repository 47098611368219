.topKeyword {
    width: 507px;
    height: 393px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topKeywordTitle {
    width: 474px;
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
    margin-left: 10px;
}

.topKeywordTable {
    width: 474px;
    height: 308px;
    margin-top: 20px;
}

.topKeywordTableHeader {
    position: relative;
    width: 100%;
    height: 27px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 2;
    display: flex;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 14px;
    font-weight: 700;
}

.headerCell {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    &:last-child {
        border-right: none;
    }
}

.topKeywordTableBody {
    width: 100%;
}

.tableRow {
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    overflow: visible;
    & div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 27px;
        border-right: 1px solid rgba(0, 0, 0, 0.05);

        font-size: 14px;
        font-weight: 600;
    }
}

.tableCell {
    width: 100%;
    height: 27px;
    position: relative;
    flex-shrink: 1;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tableCell p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableCell:hover .tooltipTxt {
    visibility: visible;
    opacity: 1;
}

.tooltipTxt {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: calc(100% + 5px); /* p 태그 바로 아래로 배치 */
    left: 50%; /* 중앙 정렬 */
    transform: translateX(-50%); /* 중앙 정렬 */
    z-index: 10;
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 5px;
    transition: visibility 0.2s, opacity 0.2s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 12px; /* Tooltip 글자 크기 */
}

.cellContent {
    position: relative;
    flex: 1;
    & span {
        z-index: 1;
    }
}

.bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    opacity: 1;
    z-index: 0;
}