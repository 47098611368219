.naverContent {
    width: 220px;
    height: 135px;
    border-radius: 20px;
    background-color: #03C75A;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    flex-shrink: 0;
    cursor: pointer;
}

.naverBox {
    width: 198px;
    height: 113px;
    border: 3px solid #FFF;
    border-radius: 15px;
}

.naverImg {
    margin-top: 12px;
    margin-left: 15px;
}

.naverTxt1 {
    font-size: 13px;
    font-weight: 700;
    color: #FFF;
    margin-left: 15px;
}

.naverTxt2 {
    font-size: 10px;
    font-weight: 400;
    color: #FFF;
    margin-left: 15px;
    margin-top: 2px;
    height: 18px;
}

.naverTxt3 {
    margin-top: 5px;
    width: 95%;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: 300;
    color: #FFF;
}

.kakaoContent {
    width: 220px;
    height: 135px;
    border-radius: 20px;
    background-color: #FFE812;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    flex-shrink: 0;
    cursor: pointer;
}

.kakaoBox {
    width: 198px;
    height: 113px;
    border: 3px solid #000;
    border-radius: 15px;
}

.kakaoImg {
    margin-top: 6px;
    margin-left: 5px;
}

.kakaoTxt1 {
    font-size: 13px;
    font-weight: 700;
    color: #000;
    margin-left: 7px;
    margin-top: 5px;
}

.kakaoTxt2 {
    font-size: 10px;
    font-weight: 500;
    color: #000;
    margin-left: 7px;
    margin-top: 2px;
    height: 18px;
}

.kakaoTxt3 {
    margin-top: 5px;
    width: 95%;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: 500;
    color: #000;
}

.metaContent {
    width: 220px;
    height: 135px;
    border-radius: 20px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    flex-shrink: 0;
    cursor: pointer;
}

.metaBox {
    width: 198px;
    height: 113px;
    border: 3px solid #0173D6;
    border-radius: 15px;
}

.metaImg {
    margin-top: 9px;
    margin-left: 15px;
}

.metaTxt1 {
    font-size: 13px;
    font-weight: 700;
    color: #0173D6;
    margin-left: 15px;
}

.metaTxt2 {
    font-size: 10px;
    font-weight: 500;
    color: #000;
    margin-left: 15px;
    margin-top: 2px;
}

.metaTxt3 {
    margin-top: 5px;
    width: 95%;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: 500;
    color: #000;
}

.googleContent {
    width: 220px;
    height: 135px;
    border-radius: 20px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    flex-shrink: 0;
    cursor: pointer;
}

.googleBox {
    width: 198px;
    height: 113px;
    border: 3px solid #3F8CD9;
    border-radius: 15px;
}

.googleImg {
    margin-top: 12px;
    margin-left: 10px;
}

.googleTxt1 {
    font-size: 13px;
    font-weight: 700;
    color: #3F8CD9;
    margin-left: 15px;
}

.googleTxt2 {
    font-size: 10px;
    font-weight: 500;
    color: #000;
    margin-left: 15px;
    margin-top: 2px;
    height: 18px;
}

.googleTxt3 {
    margin-top: 5px;
    width: 95%;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: 500;
    color: #000;
}

.ga4Content {
    width: 220px;
    height: 135px;
    border-radius: 20px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    flex-shrink: 0;
    cursor: pointer;
}

.ga4Box {
    width: 198px;
    height: 113px;
    border: 3px solid #FF9416;
    border-radius: 15px;
}

.ga4Img {
    margin-top: 15px;
    margin-left: 10px;
}

.ga4Txt1 {
    font-size: 13px;
    font-weight: 700;
    color: #FF9416;
    margin-left: 15px;
}

.ga4Txt2 {
    font-size: 10px;
    font-weight: 500;
    color: #000;
    margin-left: 15px;
    margin-top: 2px;
    height: 18px;
}

.ga4Txt3 {
    margin-top: 5px;
    width: 95%;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: 500;
    color: #000;
}

.predictContent {
    width: 220px;
    height: 135px;
    border-radius: 20px;
    background-color: #00A0A0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    flex-shrink: 0;
    cursor: pointer;
}

.predictBox {
    width: 198px;
    height: 113px;
    border: 3px solid #FFF;
    border-radius: 15px;
}

.predictImg {
    margin-top: 15px;
    margin-left: 15px;
}

.predictTxt1 {
    font-size: 13px;
    font-weight: 600;
    color: #FFF;
    margin-left: 15px;
    margin-top: 3px;
}

.predictTxt2 {
    font-size: 10px;
    font-weight: 400;
    color: #FFF;
    margin-left: 15px;
    margin-top: 2px;
    height: 18px;
}

.predictTxt3 {
    margin-top: 5px;
    width: 95%;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: 300;
    color: #FFF;
}

.rfmContent {
    width: 220px;
    height: 135px;
    border-radius: 20px;
    background-color: #3A933D;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    flex-shrink: 0;
    cursor: pointer;
}

.totalGa4Content {
    width: 220px;
    height: 135px;
    border-radius: 20px;
    background-color: #FF9416;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    flex-shrink: 0;
    cursor: pointer;
}

.totalMediaContent {
    width: 220px;
    height: 135px;
    border-radius: 20px;
    background-color: #8255DB;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    flex-shrink: 0;
    cursor: pointer;
}

.contentBtnStyle {
    cursor: pointer;
    transition: transform 0.15s ease-in-out;
}

.contentBtnStyle:hover {
    transform: scale(1.05); /* 마우스를 올렸을 때 1.1배 크기로 확대 */
}