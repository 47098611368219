.predScoreCard {
    width: 1320px;
    height: 133px;
    display: flex;
    gap: 15px;
    margin-bottom: 40px;
}

.scoreCard {
    width: 177px;
    height: 124px;
    background-color: #FFF;
    border-radius: 10px;
    border: 4px solid rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.advCostDate {
    width: 1300px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.predTitle {
    width: 1300px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & p {
        font-size: 20px;
        font-weight: 800;
    }
    margin-bottom: 10px;
}

.scoreCardTitle {
    font-size: 13px;
    font-weight: 600;
    color: #000;
}

.scoreCardValue {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.scoreCardChange {
    font-size: 11px;
    font-weight: 600;
    color: #848484;
}

.positive {
    color: #0048FF;
}

.negative {
    color: #FF0000;
}