.creratedList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.listContainer {
    width: 826px;
    height: 228px;
    display: flex;
    justify-content: space-between;
}

.listBox {
    width: 390px;
    height: 228px;
    border-radius: 5px;
    background-color: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.optionIcon {
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: flex-end;
    margin-top: 13px;
    padding-right: 20px;
    position: relative;
    cursor: pointer;
}

.modal {
    position: absolute;
    right: 0px;
    top: 0px;
    // right: 0px;
    width: 74px;
    height: 81px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & button {
        width: 63px;
        height: 30px;
        border: none;
        border-radius: 5px;
        background-color: #FFF;
        font-size: 10px;
        font-weight: 700;
        &:hover {
            background-color: rgba(0, 160, 160, 0.2);
        }
        cursor: pointer;
    }
}

.listName {
    width: 333px;
    font-size: 16px;
    font-weight: 800;
    margin-top: 8px;

}

.predName {
    width: 333px;
    height: 24px;
    margin-top: 8px;
    & p {
        width: 144px;
        height: 24px;
        border-radius: 5px;
        background-color: rgba(0, 160, 160, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        font-size: 13px;
        font-weight: 800;
    }
}

.dataBox {
    width: 333px;
    height: 95px;
    border: 2px solid rgba(0, 160, 160, 0.2);
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    & p {
        font-size: 13px;
        font-weight: 700;
    }
    & ul {
        list-style-position: outside;
        padding-left: 10px;
        margin-top: 2px;
    }
    & li {
        text-indent: 5px !important;
        font-size: 11px;
        font-weight: 600;
        margin-top: 2px;
    }
    & li::marker {
        content: "•";
    }
}

.btnBox {
    width: 333px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    & p {
        font-size: 13px;
        font-weight: 600;
        cursor: pointer;
    }
}

.noData {
    width: 826px;
    height: 169px;
    background-color: rgba(0, 160, 160, 0.1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noDataTxt {
    font-size: 20px;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.4);
}