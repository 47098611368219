.daFunnel {
    width: 870px;
    height: 774px;
    background-color: #FFF;
    border-radius: 5px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.daFunnelTitle {
    width: 827px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & p {
        font-size: 20px;
        font-weight: 800;
    }
    margin-top: 20px;
    margin-bottom: 50px;

}

.scoreCardBox {
    width: 828px;
    height: 137px;
    display: flex;
    justify-content: space-between;
}

.funnelScoreCard {
    width: 250px;
    height: 137px;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    border-radius: 10px;
    padding: 20px;
}

.cardName {
    font-size: 15px;
    font-weight: 700;
}

.cardPer {
    font-size: 15px;
    font-weight: 700;
    margin-top: 15px;
    margin-left: 15px;
}

.cardData {
    font-size: 15px;
    font-weight: 700;
    margin-left: 15px;
}

.funnelChart {
    width: 837px;
    height: 263px;
    margin-top: 60px;
    margin-bottom: 50px;
    display: flex;
}

.funnelTicks {
    width: 68px;
    height: 263px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    & p {
        height: 37px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
    }
}

.funnelBox {
    width: 771px;
    height: 263px;
    border-bottom: 1px solid #000;
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    gap: 15px;
}

.firstStep {
    width: 247px;
    height: 245px;
    background-color: rgba(255, 180, 18, 0.2);
}

.secondStep {
    width: 247px;
    background-color: rgba(255, 180, 18, 0.2);
}

.thirdStep {
    width: 247px;
    background-color: rgba(255, 180, 18, 0.2);
}

.funnelTable {
    width: 804px;
    height: 131px;
    display: flex;
    border: 1px solid rgba(0,0,0,0.05);
}

.tableCol {
    width: 268px;
    height: 131px;
    border-right: 1px solid rgba(0,0,0,0.05);
    &:last-child {
        border: none;
    }
}

.tableHeader {
    width: 268px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    font-size: 14px;
    font-weight: 700;
}

.tableMiddle {
    width: 268px;
    height: 77px;
    background-color: #F6F7F6;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    padding-right: 20px;
}

.tableBottom {
    width: 268px;
    height: 27px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
    padding-right: 15px;
    font-size: 12px;
    font-weight: 600;
}