.selectBox {
    width: 430px;
    margin-top: 20px;
    & p {
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;
    }
}

.select {
    width: 100%;
    height: auto;
    & select {
        width: 100%;
        height: 100%;
        border: 1px solid #BDC2C7;
        border-radius: 5px;
        color: #8C8C8C;
        & option {
            color: #8C8C8C;
            
        }
    }
}

.selectBtn {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    & button {
        width: 50px;
        height: 25px;
        background-color: #00A0A0;
        border: none;
        border-radius: 5px;
        color: #FFF;
        font-size: 12px;
        font-weight: 800;
        cursor: pointer;
    }
}

.inputContainer {
    width: 430px;
    height: 380px;
    margin-top: 20px;
    overflow-y: scroll;
}

.inputContainer::-webkit-scrollbar {
    width: 5px; /* 스크롤바 너비 */
    display: none;
}

.inputContainer::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙 배경색 */
    border-radius: 10px; /* 트랙 경계선 둥글게 */
    display: none;
}

.inputContainer::-webkit-scrollbar-thumb {
    background: #E9E9E9; /* 썸 배경색 */
    border-radius: 10px; /* 썸 경계선 둥글게 */
    height: 164px;
}

.inputContainer::-webkit-scrollbar-thumb:hover {
    background: #E9E9E9; /* 썸 hover 시 배경색 */
}

.inputBox {
    width: 100%;
    height: 81px;
    border-radius: 5px;
    background-color: #F4F6F8;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputTxt {
    width: 384px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}

.inputContent {
    width: 384px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & input {
        width: 351px;
        height: 32px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        padding-left: 10px;
    }
    & input::placeholder {
        font-size: 10px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.5);
    }
}