@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-Thin.woff') format('woff'),
        url('/assets/fonts/Pretendard-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-ExtraLight.woff') format('woff'),
        url('/assets/fonts/Pretendard-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-Light.woff') format('woff'),
        url('/assets/fonts/Pretendard-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-Regular.woff') format('woff'),
        url('/assets/fonts/Pretendard-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-Medium.woff') format('woff'),
        url('/assets/fonts/Pretendard-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-SemiBold.woff') format('woff'),
        url('/assets/fonts/Pretendard-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-ExtraBold.woff') format('woff'),
        url('/assets/fonts/Pretendard-ExtraBold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-Bold.woff') format('woff'),
        url('/assets/fonts/Pretendard-Bold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-Black.woff') format('woff'),
        url('/assets/fonts/Pretendard-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Pretendard;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url(./scss/_reset.scss);