.daCreativeTop {
    width: 870px;
    height: 609px;
    background-color: #FFF;
    border-radius: 5px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.daCreativeTopTitle {
    width: 827px;
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
}

.daCreativeTopSelect {
    width: 827px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 20px;
    & select {
        width: 110px;
        height: 28px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 600;
        padding-left: 5px;
    }
}

.selectBox6 {
    border: 2px solid rgba(0, 176, 80, 0.6);
    color: #00B050;
}

.selectBox7 {
    border: 2px solid rgba(255, 180, 18, 0.6);
    color: #000;
}

.selectBox100 {
    border: 2px solid rgba(46, 103, 156, 0.6);
    color: #000;
}

.daCreativeBox {
    width: 827px;
    height: 483px;
    display: flex;
    gap: 5px;
}

.imgBox {
    width: 273px;
    height: 187px;
    border-radius: 5px;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    margin-bottom: 10px;
}

.daCreativeContainer {
    display: flex;
    flex-direction: column;
}

.daCreativeTable {
    width: 273px;
    height: 287px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    padding: 10px;
}

.tableRow {
    display: flex;
    gap: 5px;
    &:last-child {
        & .tableHeader p {width: 50px;}
    }
}

.tableHeader {
    width: 75px;
    height: 36px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    & p {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.tableBody {
    width: 175px;
    height: 36px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    & p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}