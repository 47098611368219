.evMain {
    width: 100%;
    height: 953px;
}

.evHeader {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgba(0,160,160,0.5);
    & svg {
        margin-left: 20px;
    }
}

.evBody {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.evTxt1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}

.evTxt2 {
    font-size: 20px;
    font-weight: 500;
}

.evTxt3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
}

.evIdBox {
    width: 456px;
    height: 63px;
    background-color: #F2F2F2;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
}

.evBtnBox {
    width: 456px;
    height: 50px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        color: #FFF;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
    }
}