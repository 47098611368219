.report {
    width: 1021px;
    height: 284px;
    background-color: #FFF;
    margin-top: 20px;
    border-radius: 20px;
    display: flex;
}

.currentBox {
    width: 40%;
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.currentTxt {
    width: 320px;
    font-size: 20px;
    font-weight: 800;
    margin-top: 15px;
}

.totalReport {
    width: 345px;
    height: 103px;
    background-color: #CCECEC;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.reportTxt1 {
    font-size: 16px;
    font-weight: 800;
    color: #007E7E;
    margin-top: 10px;
}

.reportTxt2 {
    font-size: 48px;
    font-weight: 800;
    color: #007E7E;
}

.createReport {
    width: 345px;
    height: 103px;
    background-color: #E8EFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.createTxt1 {
    font-size: 16px;
    font-weight: 800;
    color: #24448B;
    margin-top: 10px;
}

.createTxt2 {
    font-size: 48px;
    font-weight: 800;
    color: #24448B;
}

.recommendBox {
    width: 610px;
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.recommendTxt {
    width: 564px;
    font-size: 20px;
    font-weight: 800;
    margin-top: 15px;
}

.recommendContainer {
    width: 564px;
    height: 185px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.recommendContent {
    width: 100%;
    height: 55px;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.recommendImg {
    margin-left: 20px;
    height: 29px;
    display: flex;
    gap: 10px;
    & svg {
        width: 29px;
        height: 29px;
    }
}

.recommendTxt1 {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
    width: 440px;
}

.recommendTxt2 {
    font-size: 23px;
    font-weight: 800;
}

.recommendTxt3 {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
    width: 285px;
}