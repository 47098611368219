.keywordTrend {
    width: 333px;
    height: 393px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.keywordTrendTitle {
    width: 310px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & p {
        width: 100px;
        font-size: 20px;
        font-weight: 800;
    }
    margin-top: 20px;
}

.wordcloud {
    width: 307px;
    height: 278px;
    display: flex;
    flex-direction: column;
    user-select: none;
    margin-top: 20px;
}

.wordcloud svg {
    margin: 1rem 0;
    cursor: pointer;
}

.wordcloud label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    margin-right: 8px;
}

.wordcloud textarea {
    min-height: 100px;
}