.reportDa {
    position: fixed;
    margin-top: 54px;
    margin-left: 300px;
    width: calc(100% - 300px);
    height: calc(100% - 54px);
    background-color: #F5F4F4;
    overflow-x: scroll;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 6px; /* 스크롤바 너비 */
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* 트랙 배경색 */
        border-radius: 5px; /* 트랙 경계선 둥글게 */
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background: #00A0A0; /* 썸 배경색 */
        border-radius: 5px; /* 썸 경계선 둥글게 */
        height: 64px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #00A0A0; /* 썸 hover 시 배경색 */
    }
}

.reportDaHeader {
    width: 1612px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    position: relative;
}

.reportDaHeaderTitle {
    width: 1210px;
    font-size: 20px;
    font-weight: 700;
}

.reportDaHeaderIcon {
    
    display: flex;
    gap: 10px;
}

.reportDaButtonContainer {
    width: 1612px;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.reportDaButtonBox {
    width: 1320px;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.reportNaverDaButton {
    width: 420px;
    height: 51px;
    background-color: #FFF;
    border: 4px solid rgba(0, 176, 80, 0.2);
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    color: #00B050;
}

.reportKakaoDaButton {
    width: 420px;
    height: 51px;
    background-color: #FFF;
    border: 4px solid rgba(255, 180, 18, 0.2);
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

.reportGoogleDaButton {
    width: 420px;
    height: 51px;
    background-color: #FFF;
    border: 4px solid rgba(46, 103, 156, 0.2);
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

.reportNaverDaButtonActive {
    width: 420px;
    height: 51px;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    background-color: #00B050;
    color: #FFF;
}

.reportKakaoDaButtonActive {
    width: 420px;
    height: 51px;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    background-color: #FFB412;
    color: #000;
}

.reportGoogleDaButtonActive {
    width: 420px;
    height: 51px;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    background-color: #2E679C;
    color: #FFF;
}

.reportDaContent {
    width: 1612px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.reportDaGoal {
    width: 420px;
    height: 207px;
    background-color: #FFF;
    border-radius: 10px;
}