.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    width: 838px;
    height: 569px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalHeader {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #00A0A0;
    margin-bottom: 15px;
    & p {
        margin-left: 15px;
        font-size: 15px;
        font-weight: 700;
    }
    & div {
        margin-right: 20px;
    }
}

.modalBody {
    width: 100%;
    display: flex;
}

.calendar {
    width: 457px;
    height: 484px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-datepicker {
    width: 457px;
    height: 484px;
    border: none !important;
    border-radius: 15px !important;
    font-family: "Pretendard" !important;
}

.react-datepicker__month-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.react-datepicker__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF !important;
    padding: 0 !important;
    border: none !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.customHeaderContainer {
    margin-top: 12px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.customHeaderBox {
    margin-top: 12px;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    & button {
        border: none;
        background-color: #FFF;
        font-size: 15px;
        font-weight: 700;
        width: 20px;
        font-family: "Pretendard" !important;
    }
}

.year {
    border: none;
    font-size: 15px;
    font-weight: 700;
}

.month {
    font-size: 15px;
    font-weight: 700;
}

.weekday-container {
    width: 330px;
    display: flex;
    justify-content: space-between;
}

.weekday {
    font-size: 15px;
    font-weight: 700;
    position: relative;
    width: 35px;
    height: 18px;
    text-align: center;
    margin-top: 40px;
}

.weekday::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%; /* 요소의 중간에서 시작 */
    transform: translateX(-50%);
    width: 15px; /* 중간에 적용될 선의 길이 */
    border-bottom: 1px solid #000;
}

.react-datepicker__day-names {
    display: none;
}

.react-datepicker__month {
    width: 457px;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-datepicker__week {
    width: 370px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    & div {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 700;
    }
}

.selected-date {
    background-color: rgba(0, 160, 160, 0.2) !important;
    color: #000 !important;
    border-radius: 50% !important;
}

.react-datepicker__day:hover {
    background-color: rgba(0, 160, 160, 0.2) !important; /* hover 시 배경색 */
    color: black; /* hover 시 글자 색 */
    border-radius: 50% !important;
    cursor: pointer;
}

.react-datepicker__day--keyboard-selected {
    background-color: rgba(0, 160, 160, 0.2) !important;
    // color: #FFF !important;
    border-radius: 50% !important;
}

.react-datepicker__day--outside-month {
    cursor: default;
    visibility: hidden;
}

.react-datepicker__day--in-range {
    background-color: rgba(0, 160, 160, 0.2) !important;
    color: #000 !important;
    border-radius: 50% !important;
}

