.infoTxtBox {
    position: relative;
    display: flex;
    width: 70%;
    justify-content: flex-end;
    align-items: center;
    .tooltip {
        visibility: hidden;
        width: 100%;
        opacity: 0;
        right: 100%;
        top: 50%;
        background-color: #FFF;
        color: #000;
        text-align: center;
        border: 4px solid rgba(0, 160, 160, 0.2);
        border-radius: 10px;
        padding: 5px;
        margin-right: 5px;
        width: max-content;
        font-size: 13px;
        font-weight: 800;
        z-index: 1;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }
    .tooltip.visible {
        visibility: visible;
        opacity: 1;
    }
    & svg {
        width: 20px;
        height: 20px;
        color: rgba(0, 0, 0, 0.2);
    }
}

.infoTxtBox2 {
    position: absolute;
    display: flex;
    width: 70%;
    align-items: center;
    .tooltip {
        visibility: hidden;
        width: 100%;
        opacity: 0;
        right: 100%;
        top: 50%;
        background-color: #FFF;
        color: #000;
        text-align: center;
        border: 4px solid rgba(0, 160, 160, 0.2);
        border-radius: 10px;
        padding: 5px;
        margin-left: 5px;
        width: max-content;
        font-size: 13px;
        font-weight: 800;
        z-index: 1;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }
    .tooltip.visible {
        visibility: visible;
        opacity: 1;
    }
    & svg {
        width: 20px;
        height: 20px;
        color: rgba(0, 0, 0, 0.2);
    }
}