.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    width: 572px;
    height: 800px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalCloseBtn {
    width: 474px;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.modalText {
    width: 474px;
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
}

.text1 {
    font-size: 15px;
    font-weight: 900;
    margin-bottom: 3px;
}

.text2 {
    font-size: 15px;
    font-weight: 600;
}

.stepBox {
    width: 490px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepTxtBox {
    width: 465px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.stepTxt1 {
    font-size: 14px;
    font-weight: 600;
}

.stepTxt2 {
    width: 204px;
    font-size: 14px;
    font-weight: 600;
    color: #C6C6C6;
    padding-left: 10px;
}

.agreeBox {
    width: 373px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.agreeTxt1 {
    width: 350px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}

.agreeTxt2 {
    width: 350px;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 20px;
}

.agreeTable {
    width: 372px;
    height: 152px;
    border: 1px solid #BABABA;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.column1 {
    width: 124px;
    border-right: 1px solid #BABABA;
    & p {
        font-size: 15px;
    }
}

.column2 {
    width: 124px;
    border-right: 1px solid #BABABA;
    & p {
        font-size: 15px;
    }
}

.column3 {
    width: 124px;
    & p {
        font-size: 15px;
    }
}

.row1 {
    width: 100%;
    height: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00A0A0;
    border-bottom: 1px solid #BABABA;
    color: #FFF;
    font-weight: 600;
}

.row2 {
    width: 100%;
    height: 76px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #BABABA;
    font-weight: 500;
}

.row3 {
    width: 100%;
    height: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.row4 {
    width: 100%;
    height: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #BABABA;
    font-weight: 500;
}

.row5 {
    width: 100%;
    height: 114px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.infoTxt {
    width: 350px;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 20px;
}

.checkBox {
    width: 373px;
    height: 81px;
    background-color: #F4F6F8;
    border-radius: 5px;
}

.checkTxt {
    margin-top: 15px;
    margin-left: 30px;
    font-size: 15px;
    font-weight: 600;
}

.checkContainer {
    width: 340px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    & label {
        font-size: 15px;
        font-weight: 600;
        margin-right: 5px;
    }
}

.custom-checkbox {
    width: 14px;
    height: 14px;
    border: 1px solid #BABABA;
    background-color: #FFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 8px;
    margin-left: 10px;
    position: relative;
}

.custom-checkbox.checked::before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #BABABA;
    border-radius: 50%;
    position: absolute;
}

.nextBtn {
    width: 373px;
    height: 50px;
    margin-top: 70px;
    display: flex;
    justify-content: flex-end;
}

.deactive {
    width: 174px;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: rgba(0,160,160,0.4);
    color: #FFF;
    font-size: 20px;
    font-weight: 900;
}

.active {
    width: 174px;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    color: #FFF;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
}