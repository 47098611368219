.logout-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.logout-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 644px;
    height: 320px;
    text-align: center;
}

.logout-modal-content-description {
    font-size: 14px;
    font-weight: 800;
}

.logout-modal-content-title {
    margin-bottom: 20px;
}

.logout-modal-content p {
    margin-top: 30px;
    color: #999999;
    margin-bottom: 20px;
}

.logout-modal-button-contatiner{
    display: flex;
    margin-top: 30px;
}

.logout-modal-content button {
    height: 47px;
    width: 105px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 1px 1px 1px 1px #DEDEDE;
    font-size: 20px;
    font-weight: 800;
}
.logout-modal-cancle{
    background-color: #fff;
    color: #000;
}
.logout-modal-ok{
    background-color: #00A0A0;
    color: white;
    margin-left: 12px;
}

.logout-modal-content button:hover {
    transform: scale(1.2);
    transition-duration: 0.1s;
}
