.dayPerformanceCost {
    width: 870px;
    height: 513px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.dayPerformanceCostTitle {
    width: 827px;
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
}

.dataSelectBox {
    width: 827px;
    height: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    & div {
        height: 23px;
        display: flex;
        gap: 5px;
        & button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 8px;
            padding-right: 8px;
            border-radius: 3px;
            background-color: #FFF;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
        }
    }
}

.dataSelectButton5 {
    & button {
        border: 2px solid rgba(0, 176, 80, 0.2);
        color: #00B050;
        &.active {
            background-color: #00B050;
            color: #FFF;
        }
    }
}

.dataSelectButton89 {
    & button {
        border: 2px solid rgba(255, 180, 18, 0.2);
        color: #000;
        &.active {
            background-color: #FFB412;
            color: #000;
        }
    }
}

.dataSelectButton100 {
    & button {
        border: 2px solid rgba(0, 114, 240, 0.2);
        color: #0072F0;
        &.active {
            background-color: #0072F0;
            color: #FFF;
        }
    }
}

.excelDownload {
    width: 120px;
    height: 23px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

