.reportList {
    width: 90%;
    height: 85%;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
}

.reportList::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
}

.reportList::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙 배경색 */
    border-radius: 5px; /* 트랙 경계선 둥글게 */
    display: none;
}

.reportList::-webkit-scrollbar-thumb {
    background: #00A0A0; /* 썸 배경색 */
    border-radius: 5px; /* 썸 경계선 둥글게 */
    height: 64px;
}

.reportList::-webkit-scrollbar-thumb:hover {
    background: #00A0A0; /* 썸 hover 시 배경색 */
}

.reportItem {
    width: 250px;
    height: 37px;
    border: 1px solid #F5F4F4;
    border-radius: 5px;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 7px 0px 7px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
        background-color: #CCECEC;
        color: #00A0A0;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        border: none;
        font-weight: 700;
    }
    & p {
        width: 190px;
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.selectItem {
    width: 250px;
    height: 37px;
    border-radius: 5px;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 7px 0px 7px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #CCECEC;
    color: #00A0A0;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    & p {
        width: 190px;
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.optionIcon {
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;
    right: 10px;
}

.modal {
    position: absolute;
    // left: 0px;
    top: 26px;
    right: 0px;
    width: 74px;
    height: 81px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & button {
        width: 57px;
        height: 30px;
        border: none;
        border-radius: 5px;
        background-color: #FFF;
        font-size: 10px;
        font-weight: 700;
        &:hover {
            cursor: pointer;
            background-color: rgba(0, 160, 160, 0.2);
        }
    }
}