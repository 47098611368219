.naver .pagination {
    li {
        &:hover,
        &:active ,
        &.active{
            background-color: #00B050;
            a:-webkit-any-link {
                color: #ffffff;
                font-weight: 500;
            }
        }
    }
    a:-webkit-any-link {
        color: #00B050;
    }
}

.kakao .pagination {
    li {
        &:hover,
        &:active ,
        &.active{
            background-color: #FFB412;
            a:-webkit-any-link {
                color: #000;
                font-weight: 500;
            }
        }
    }
    a:-webkit-any-link {
        color: #FFB412;
    }
}

.google .pagination {
    li {
        &:hover,
        &:active ,
        &.active{
            background-color: #0072F0;
            a:-webkit-any-link {
                color: #FFF;
                font-weight: 500;
            }
        }
    }
    a:-webkit-any-link {
        color: #0072F0;
    }
}

.gfa .pagination {
    li {
        &:hover,
        &:active ,
        &.active{
            background-color: #00B050;
            a:-webkit-any-link {
                color: #ffffff;
                font-weight: 500;
            }
        }
    }
    a:-webkit-any-link {
        color: #00B050;
    }
}

.moment .pagination {
    li {
        &:hover,
        &:active ,
        &.active{
            background-color: #FFB412;
            a:-webkit-any-link {
                color: #000;
                font-weight: 500;
            }
        }
    }
    a:-webkit-any-link {
        color: #FFB412;
    }
}

.googleDa .pagination {
    li {
        &:hover,
        &:active ,
        &.active{
            background-color: #2E679C;
            a:-webkit-any-link {
                color: #FFF;
                font-weight: 500;
            }
        }
    }
    a:-webkit-any-link {
        color: #2E679C;
    }
}

.pagination {
    width: 827px;
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border: 1px solid #F9F9F9;
        cursor: pointer;
        &:hover,
        &:active ,
        &.active{
            cursor: pointer;
            // background-color: #00B050;
            // a:-webkit-any-link {
            //     color: #ffffff;
            //     font-weight: 500;
            // }
        }
    }
    a:-webkit-any-link {
        // color: #00B050; 
        cursor: pointer; 
        text-decoration: none;
        font-weight: 500;
        cursor: pointer;
    }
}

