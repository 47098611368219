.main {
    width: 432px;
    min-width:432px;
    height: 885px;
    background-color: rgba(0,160,160,0.1);
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.linkToBox {
    width: 354px;
    height: 173px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 25px;
}

.linkToBoxTxt {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 14px;
}

.linkToContent {
    width: 100%;
    height: 66px;
    background-color: #FFF;
    margin-bottom: 7px;
    border-radius: 10px;
    border: 5px solid rgba(0,160,160,0.7);
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.contentTxt {
    width: 280px;
    font-size: 20px;
    font-weight: 800;
    color: #007E7E;
    margin-left: 10px;
}