.mypageContainer {
    position: absolute;
    margin-left: 300px;
    height: calc(100% - 54px);
    width: calc(100% - 300px);
    top: 54px;
    background-color: #F5F4F4;
    overflow: auto;
}

.mypageContainer::-webkit-scrollbar {
    display: none;
}

.mypageContents {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bannerContainer {
    width: 90%;
    margin-top: 50px;
    min-width: 900px;
}

.detailContainer {
    position: relative;
    top: 40px;
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    border-color: #E3E3E3;
    background-color: #FFFFFF;
}

.myInfo {
    width: 758px;
    height: 222px;
    background-color: #FFF;
    margin-top: 80px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    & pre {
        width: 688px;
        font-size: 16px;
        font-weight: 600;
    }
}

.infoTxt {
    width: 688px;
    height: 39px;
    margin-top: 20px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    font-size: 16px;
    font-weight: 700;
}

.linkContainer {
    width: 758px;
    height: 223px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
}

.linkBox {
    width: 100%;
    height: 71px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 610px;
    padding-left: 35px;
    & p {
        width: 56px;
        font-size: 16px;
        font-weight: 800;
    }
    & a {
        text-decoration: none;
        color: #000;
    }
    & pre {
        font-size: 16px;
        font-weight: 800;
        cursor: pointer;
    }
}