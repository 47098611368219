.scoreCard {
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scoreCardTitle {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.scoreCardValue {
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

.scoreCardChange {
    font-size: 14px;
    font-weight: 600;
    color: #848484;
}

.positive {
    color: #0048FF;
}

.negative {
    color: #FF0000;
}