.listBox {
    width: 430px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-top: 20px;
    overflow: auto;
}

.listNaverBox {
    width: 430px;
    height: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    overflow: auto;
}

.linkedDataBox {
    width: 100%;
    height: 53px;
    background-color: #F4F6F8;
    border-radius: 5px;
    display: flex;
}

.gridContainer {
    width: 100%;
    height: 53px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    display: flex;
}

.itemInfoBox {
    width: 50%;
    margin: auto;
    margin-left: 20px;
}
.isNotTokenBox {
    width: 430px;
    height: 53px;
    position: fixed;
    border-radius: 5px;
    background-color: rgba(#F4F6F8, 0.9);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    display: flex;
    
}
.isNotTokenBox p {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.mediaUserName {
    font-size: 14px;
    font-weight: 500;
}

.mediaName {
    font-size: 11px;
    font-weight: 500;
}

.itemButtonBox {
    width: 50%;
    display: flex;
    margin: auto;
    margin-right: 20px;
    justify-content: flex-end;
    gap: 10px;
}



.infoButton {
    background-color: #00A0A0;
    color: #FFFFFF;
    font-size: 11px;
    width: 72px;
    height: 27px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 11px;
    cursor: pointer;
}

.deleteButton {
    background-color: #C3E5E6;
    color: #007E7E;
    width: 55px;
    height: 27px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 11px;
    cursor: pointer;
}