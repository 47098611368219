.main {
    position: fixed;
    margin-top: 54px;
    margin-left: 300px;
    width: calc(100% - 300px);
    height: calc(100% - 54px);
    background-color: #F5F4F4;
    overflow-x: scroll;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 6px; /* 스크롤바 너비 */
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* 트랙 배경색 */
        border-radius: 5px; /* 트랙 경계선 둥글게 */
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background: #00A0A0; /* 썸 배경색 */
        border-radius: 5px; /* 썸 경계선 둥글게 */
        height: 64px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #00A0A0; /* 썸 hover 시 배경색 */
    }
}

.title {
    margin-top: 30px;
    margin-left: 70px;
}

.container {
    display: flex;
    margin-left: 70px;
    width: 1570px;
    margin-bottom: 40px;
}