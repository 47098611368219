.bannerImg {
    margin-top: 10px;
    margin-left: 30px;
}

.bannerBtnBox {
    position: absolute;
    margin-top: 10px;
    margin-left: 30px;
    width: 1021px;
    height: 356px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.bannerBtn {
    width: 923px;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 75px;
}

.bannerBtnStyle {
    cursor: pointer;
    transition: transform 0.15s ease-in-out;
}

.bannerBtnStyle:hover {
    transform: scale(1.15); /* 마우스를 올렸을 때 1.1배 크기로 확대 */
}

.bannerTxtBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.bannerTxt1 {
    font-size: 20px;
    font-weight: 800;
    color: #FFF;
}

.bannerTxt2 {
    font-size: 40px;
    font-weight: 800;
    color: #FFF;
    margin-bottom: 20px;
}

.bannerTxt3 {
    font-size: 8px;
    font-weight: 500;
    color: #FFF;
}

.indicatorContainer {
    width: 92px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.indicator::marker {
    display: none;
}

.indicator::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: rgba(255,255,255,0.6); 
    border-radius: 50%;
}

.indicator.active::marker {
    display: none;
}

.indicator.active::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: #FFF;
    border-radius: 50%;
}