.deviceConversion {
    width: 420px;
    height: 405px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deviceConversionTitle {
    width: 380px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & p {
        font-size: 20px;
        font-weight: 600;
    }
}

.deviceConversionSelect {
    height: 30px;
    & select {
        width: 109px;
        height: 28px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 600;
        padding-left: 5px;
    }
}

.selectBox5 {
    border: 2px solid rgba(0, 176, 80, 0.6);
    color: #00B050;
}

.selectBox89 {
    border: 2px solid rgba(255, 180, 18, 0.6);
    color: #000;
}

.selectBox100 {
    border: 2px solid rgba(0, 114, 240, 0.6);
    color: #0072F0;
}

.deviceConversionContent {
    width: 300px;
    height: 300px;
    margin-top: 20px;
}