.mainSide {
    display: flex;
    margin-top: 54px;
    width: 300px;
    height: calc(100% - 54px);
    position: absolute;
}

.sideBar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
    // height: calc(100% - 54px);
    height: 100%;
    position: absolute;
    background-color: #F5F4F4;
}

.sideButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    width: 289px;
    height: 213px;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 10px;
    gap: 15px;
}

.sideButtonBtn {
    width: 90%;
    height: 42px;
    background-color: #FFFFFF;
    border: none;
    display: flex;
    color: #000;
    font-size: 16px;
    font-weight: 800;
    align-items: center;
    cursor: pointer;

    & svg {
        width: 30px;
        margin-left: 15px;
        margin-right: 5px;
    }

    & a {
        // width: 100%;
        color: #000000;
        font-size: 16px;
        font-weight: 800;
        background-color: #FFFFFF;
        border: none;
        display: flex;
        align-items: center;
        text-decoration: none;
    }
    & p {
        width: 33px;
        height: 14px;
        border-radius: 4px;
        color: #FFF;
        background-color: #000;
        font-size: 10px;
        font-weight: 600;
        margin-left: 5px;
    }
}

.sideActive {
    width: 90%;
    height: 42px;
    background-color: rgba(0, 160, 160, 0.2);
    color: #00A0A0;
    border: none;
    border-radius: 10px;
    display: flex;
    font-size: 16px;
    font-weight: 800;
    align-items: center;
    cursor: pointer;

    & path {
        fill: #00A0A0;
    }

    & svg {
        width: 30px;
        margin-left: 15px;
        margin-right: 5px;
    }

    & a {
        // width: 100%;
        color: #00A0A0;
        font-size: 16px;
        font-weight: 800;
        background-color: rgba(0, 160, 160, 0);
        border: none;
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    & p {
        width: 33px;
        height: 14px;
        border-radius: 4px;
        color: #FFF;
        background-color: #00A0A0;
        font-size: 10px;
        font-weight: 600;
        margin-left: 5px;
    }
}

.sidePageAdd {
    width: 289px;
    height: 73%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #FFF;
    border-radius: 10px;
}

.sidePageAddBtn {
    width: 90%;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    height: 57px;
    & a {
        width: 260px;
        height: 57px;
        text-decoration: none;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
    }
}

.sideBar2 {
    width: 197px;
    height: 100%;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25); 
    border: 1px solid #FCFCFC;
    margin-left: 300px;
    position: fixed;
    z-index: 1;
    background-color: #FDFDFD;
}

.reportBox {
    width: 100%;
    height: 88%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reportTitle {
    width: 90%;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #F5F4F4;
    padding-bottom: 8px;
    margin-top: 15px;
}