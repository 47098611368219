.clickTrend {
    width: 870px;
    height: 405px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.clickTrendTitle {
    width: 827px;
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
}

.clickTrendChart {
    width: 827px;
    height: 221px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 10px;
}

.trendBtn {
    width: 230px;
    height: 28px;
    display: flex;
    gap: 10px;
    & button {
        width: 109px;
        height: 100%;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 600;
    }
}

.activeTrend5 {
    background-color: #00B050;
    color: #FFF;
    border: none;
}

.inactiveTrend5 {
    background-color: #FFF;
    color: #00B050;
    border: 2px solid #00B050;
}

.activeTrend89 {
    background-color: #FFB412;
    color: #000;
    border: none;
}

.inactiveTrend89 {
    background-color: #FFF;
    color: #000;
    border: 2px solid rgba(255, 180, 18, 0.6);
}

.activeTrend100 {
    background-color: #0072F0;
    color: #FFF;
    border: none;
}

.inactiveTrend100 {
    background-color: #FFF;
    color: #0072F0;
    border: 2px solid rgba(0, 114, 240, 0.6);
}

.chartLabels {
    width: 80%;
    height: 17px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.clickTrendSelect {
    width: 827px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 15px;
    & select {
        width: 88px;
        height: 28px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 600;
        padding-left: 5px;
    }
}

.selectBox5 {
    border: 2px solid rgba(0, 176, 80, 0.6);
    color: #00B050;
}

.selectBox89 {
    border: 2px solid rgba(255, 180, 18, 0.6);
    color: #000;
}

.selectBox100 {
    border: 2px solid rgba(0, 114, 240, 0.6);
    color: #0072F0;
}
