.main-footer {
    width: 100%;
    height: 198px;
    background-color: #FDFDFD;
    // position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // margin-top: auto;
}

.footer-top-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 30px;
}

.footer-top-1 {
    display: flex;
    justify-content: flex-start;
    width: 350px;
    & p {
        margin-right: 15px;
        font-weight: 800;
        font-size: 16px;
    }
}

.footer-top-2 {
    display: flex;
    justify-content: flex-end;
    width: 350px;
    & p {
        margin-left: 15px;
        font-weight: 800;
        font-size: 16px;
    }
}

.footer-bottom-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    & p {
        font-size: 14px;
    }
}

.footer-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.footer-left-div {
    display: flex;
    & p {
        margin-right: 15px;
    }
}

.footer-right-div {
    margin-bottom: 10px;
    & p {
        display: flex;
        justify-content: flex-end;
    }
}