.main {
    width: 546px;
    height: 758px;
    box-shadow: inset 0px 4px 4px 0px rgba(0,0,0,0.25),
                inset 0px -4px 4px 0px rgba(0,0,0,0.25);
    border-radius: 10px;
    margin-top: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.listContainer {
    width: 521px;
    height: 710px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5px;
    &::-webkit-scrollbar {
        width: 6px; /* 스크롤바 너비 */
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* 트랙 배경색 */
        border-radius: 5px; /* 트랙 경계선 둥글게 */
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background: #00A0A0; /* 썸 배경색 */
        border-radius: 5px; /* 썸 경계선 둥글게 */
        height: 64px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #00A0A0; /* 썸 hover 시 배경색 */
    }
}

.listBox {
    width: 507px;
    height: 176px;
    background-color: #FFF;
    border-radius: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
}

.listBox.open {
    width: 507px;
    height: 378px;
    background-color: #FFF;
    border-radius: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list {
    width: 487px;
    height: 158px;
    border: 2px solid #00A0A0;
    border-radius: 15px;
}

.list.open {
    width: 487px;
    height: 354px;
    border: 2px solid #00A0A0;
    border-radius: 15px;
}

.mediaImg {
    // width: 139px;
    margin-top: 10px;
    margin-left: 20px;
    display: flex;
    gap: 5px;
    & svg {
        width: 22px;
        height: 22px;
    }
}

.listTxt1 {
    width: 447px;
    font-size: 20px;
    font-weight: 800;
    // margin-top: 10px;
    margin-left: 20px;
}

.listTxt2 {
    width: 447px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    margin-left: 20px;
}

.detailBtn {
    width: 447px;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.detailBtnTxt1 {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.detailBtnTxt2 {
    font-size: 16px;
    font-weight: 500;
    transform: rotate(90deg); /* 90도 회전 */
    margin-left: 5px;
}

.detailBtnTxt3 {
    font-size: 16px;
    font-weight: 500;
    transform: rotate(270deg);
    margin-left: 5px;
}

.dataLink {
    width: 438px;
    height: 71px;
    margin-top: 20px;
    margin-left: 20px;
    padding-left: 30px;
    border: 1px solid #00A0A0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
    & li {
        font-size: 14px;
    }
    & li::marker {
        font-size: 14px;
        transform: scale(0.1);
    }
}

.dataLinkBtn {
    width: 438px;
    height: 45px;
    margin-top: 8px;
    margin-left: 20px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        background-color: #00A0A0;
        border-radius: 5px;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        & a {
            text-decoration: none;
            color: #FFF;
        }
    }
}

.reportBtn {
    width: 438px;
    height: 45px;
    margin-top: 8px;
    margin-left: 20px;
    // margin-bottom: 20px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        background-color: #00A0A0;
        border-radius: 5px;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
    }
}