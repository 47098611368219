.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    width: 580px;
    height: 845px;
    background-color: #FFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
}

.modalCloseBtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.modalTitle {
    width: 420px;
    border-bottom: 2px solid #00A0A0;
    margin-bottom: 25px;
    & p {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 10px;
    }
}

.mediaLinkBox {
    width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
}

.txtBox {
    width: 450px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.txtBoxTxt1 {
    font-size: 14px;
    font-weight: 600;
}

.txtBoxTxt2 {
    font-size: 14px;
    font-weight: 700;
}

.advMediaBox {
    width: 378px;
    display: flex;
    flex-direction: column;
}

.inputBox {
    width: 378px;
    height: 48px;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    margin-top: 15px;
    padding-left: 15px;
    &::placeholder {
        font-size: 15px;
        font-weight: 600;
        color: #D2D2D2;
    }
}

.advMediaBoxTxt1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}

.advMediaBoxTxt2 {
    font-size: 12px;
    font-weight: 600;
}

.dataCheckBox {
    width: 390px;
    height: 350px;
    margin-top: 30px;
    margin-left: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.dataCheckBox::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
}

.dataCheckBox::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙 배경색 */
    border-radius: 5px; /* 트랙 경계선 둥글게 */
    display: none;
}

.dataCheckBox::-webkit-scrollbar-thumb {
    background: #00A0A0; /* 썸 배경색 */
    border-radius: 5px; /* 썸 경계선 둥글게 */
    height: 64px;
}

.dataCheckBox::-webkit-scrollbar-thumb:hover {
    background: #00A0A0; /* 썸 hover 시 배경색 */
}

.checkBoxTxt {
    font-size: 18px;
    font-weight: 600;
}

.checkData {
    width: 378px;
    height: 48px;
    background-color: #F4F5F6;
    color: #00A0A0;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 600;
}

.modalBtnBox {
    width: 378px;
    height: 55px;
    display: flex;
    justify-content: space-between;
}

.prevBtn {
    width: 168px;
    height: 100%;
    border: none;
    border-radius: 5px;
    background-color: #F4F5F6;
    color: #00A0A0;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}

.nextBtn {
    width: 168px;
    height: 100%;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}