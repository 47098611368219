.linkDataList {
    width: 95%;
    height: 760px;
    background-color: #FFF;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.linkDataListText {
    width: 90%;
    margin-top: 30px;
}

.linkDataListBox {
    width: 90%;
    height: 680px;
    overflow-y: scroll;
    margin-top: 10px;
    padding-right: 5px;
}

.linkDataListBox::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
}

.linkDataListBox::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙 배경색 */
    border-radius: 5px; /* 트랙 경계선 둥글게 */
    display: none;
}

.linkDataListBox::-webkit-scrollbar-thumb {
    background: #00A0A0; /* 썸 배경색 */
    border-radius: 5px; /* 썸 경계선 둥글게 */
    height: 64px;
}

.linkDataListBox::-webkit-scrollbar-thumb:hover {
    background: #00A0A0; /* 썸 hover 시 배경색 */
}

.listContent {
    width: 98%;
    height: 60px;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
}

.contentImg {
    width: 7%;
    display: flex;
    justify-content: center;
    & svg {
        width: 25px;
        height: 25px;
    }
}

.reportName {
    width: 40%;
    font-size: 16px;
    font-weight: 800;
}

.collectTime {
    width: 9%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    font-size: 10px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
}

.mediaName {
    width: 14%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #EAEAEA;
    border-right: 1px solid #EAEAEA;
    font-size: 12px;
    font-weight: 600;
}

.contentTxt {
    width: 12%;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.contentTxt::-webkit-scrollbar {
    display: none;
}

.collectBtnBox {
    width: 10%;
}

.collectBtn {
    height: 30px;
    width: 95%;
    color: #FFF;
    background-color: #00A0A0;
    border-radius: 5px;
    border: none;
    font-size: 13px;
    font-weight: 800;
    cursor: pointer;
}

.contentOption {
    width: 30px;
    position: relative;
}

.contentOptionBtn {
    width: 30px;
    height: 30px;
    background-color: #FFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dropdownBox {
    position: absolute;
    top: 100%; /* 버튼 바로 아래에 위치하도록 설정 */
    right: 0;
    background-color: white;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 145px;
    height: 104px;
    z-index: 100; /* 다른 요소 위에 나타나게 설정 */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.border {
    width: 133px;
    border: 1px solid #EAEAEA;
}

.unlinkBtn {
    width: 135px;
    height: 38px;
    margin-top: 3px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        font-size: 15px;
        font-weight: 800;

        background-color: #FFF;
    }
}

.unlinkBtn button:hover {
    width: 100%;
    height: 100%;
    border: none;
    font-size: 15px;
    font-weight: 800;
    background-color: #CCECEC;
    color: #007E7E;
    border-radius: 5px;
}

.changeNmBtn {
    width: 135px;
    height: 38px;
    margin-bottom: 3px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        font-size: 15px;
        font-weight: 800;

        background-color: #FFF;
    }
}

.changeNmBtn button:hover {
    width: 100%;
    height: 100%;
    border: none;
    font-size: 15px;
    font-weight: 800;
    background-color: #CCECEC;
    color: #007E7E;
    border-radius: 5px;
}