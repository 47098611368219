.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    width: 572px;
    height: 800px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalCloseBtn {
    width: 474px;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.modalText {
    width: 474px;
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
}

.text1 {
    font-size: 15px;
    font-weight: 900;
    margin-bottom: 3px;
}

.text2 {
    font-size: 15px;
    font-weight: 600;
}

.successTxtBox {
    width: 256px;
    height: 206px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    & svg {
        margin-bottom: 20px;
    }
}

.successTxt1 {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 20px;
}

.successTxt2 {
    font-size: 15px;
    font-weight: 500;
}

.requestInfo {
    width: 476px;
    height: 89px;
    margin-top: 20px;
}

.requestTxt1 {
    font-size: 15px;
    font-weight: 900;
    margin-bottom: 10px;
    margin-left: 10px;
}

.requestData {
    width: 476px;
    height: 51px;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    padding-left: 15px;
    padding-right: 10px;
}

.requestTxt2 {
    width: 96px;
    height: 33px;
    font-size: 11px;
    font-weight: 500;
    border-left: 1px solid #EAEAEA;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnBox {
    width: 476px;
    height: 64px;
    margin-top: 30px;
    & button {
        width: 476px;
        height: 64px;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        color: #FFF;
        font-size: 20px;
        font-weight: 900;
    }
}