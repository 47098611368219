.dataLinkBox {
    width: 437px;
    // max-width: 601px;
    // min-width: 518px;
    height: 800px;
    background-color: #FFF;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 8px solid rgba(0,160,160,0.4);
    justify-content: center;
}

.dataLinkImg {
    margin-bottom: 50px;
}

.modalText {
    width: 370px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;
}

.text1 {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 3px;
}

.listBox {
    width: 370px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    margin-top: 30px;
}

.list {
    width: 108px;
    height: 108px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    & svg {
        width: 50px;
        height: 50px;
    }
    & p {
        font-size: 13px;
        font-weight: 600;
        margin-top: 10px;
    }
}

.dataLinkBtn {
    width: 335px;
    height: 44px;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    color: #FFF;
    font-size: 15px;
    font-weight: 800;
    margin-bottom: 20px;
    cursor: pointer;
}

.dataLinkText {
    width: 75%;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
}

.dataLinkList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin-top: 10px;
    & button {
        border: none;
        background-color: #FFF;
        cursor: pointer;
    }
}

.box {
    width: 30%;
}

.content {
    width: 100%;
    height: 38px;
    display: flex;                                                                               
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
    font-size: 15px;
    font-weight: 600;
    & p {
        margin-left: 10px;
    }
}

.boxContainer {
    width: 90%;
    display: flex;
    justify-content: space-between;
}