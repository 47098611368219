.Loading-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    // border: 1px solid #E3E3E3;
    border-radius: 10px;
    margin-bottom: 20px;
}

.Loading-text-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    & p {
        color: #939393;
        font-weight: 600;
    }
}