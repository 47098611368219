.daTrendTable {
    width: 870px;
    height: 483px;
    // background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dataSelectBox {
    width: 827px;
    height: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & div {
        height: 23px;
        display: flex;
        gap: 5px;
        & button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 8px;
            padding-right: 8px;
            border-radius: 3px;
            background-color: #FFF;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
        }
    }
}

.dataSelectButton6 {
    & button {
        border: 2px solid rgba(0, 176, 80, 0.2);
        color: #00B050;
        &.active {
            background-color: #00B050;
            color: #FFF;
        }
    }
}

.dataSelectButton7 {
    & button {
        border: 2px solid rgba(255, 180, 18, 0.2);
        color: #000;
        &.active {
            background-color: #FFB412;
            color: #000;
        }
    }
}

.dataSelectButton100 {
    & button {
        border: 2px solid rgba(46, 103, 156, 0.2);
        color: #2E679C;
        &.active {
            background-color: #2E679C;
            color: #FFF;
        }
    }
}

.excelDownload {
    width: 120px;
    height: 23px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

