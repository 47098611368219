.reportKeyword {
    width: 870px;
    margin-right: 30px;
}

.conversion {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.keywordPerformance {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}