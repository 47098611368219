.period {
    position: fixed;
    margin-top: 54px;
    margin-left: 300px;
    width: calc(100% - 300px);
    height: calc(100% - 54px);
    background-color: #F5F4F4;
    overflow-x: scroll;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 6px; /* 스크롤바 너비 */
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* 트랙 배경색 */
        border-radius: 5px; /* 트랙 경계선 둥글게 */
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background: #00A0A0; /* 썸 배경색 */
        border-radius: 5px; /* 썸 경계선 둥글게 */
        height: 64px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #00A0A0; /* 썸 hover 시 배경색 */
    }
}

.periodHeader {
    width: 1612px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    position: relative;
    & p {
        width: 1410px;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 40px;
    }
}

.periodBody {
    width: 1612px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.periodBtn {
    width: 1316px;
    height: 51px;
    display: flex;
    justify-content: space-between;
    & button {
        width: 635px;
        height: 51px;
        border: 4px solid rgba(0, 160, 160, 0.2);
        border-radius: 10px;
        background-color: #FFF;
        color: #000;
        font-size: 20px;
        font-weight: 700;
    }
    & .activeBtn {
        width: 635px;
        height: 51px;
        border: none;
        border-radius: 10px;
        background-color: #00A0A0;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
    }
}

