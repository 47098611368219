.pwcMain {
    width: 100%;
    height: 953px;
}

.pwcHeader {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgba(0,160,160,0.5);
    & svg {
        margin-left: 20px;
    }
}

.pwcBody {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
}