.box {
    width: 456px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pwchangeTxt1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 7px;
}

.pwchangeTxt2 {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
    margin-bottom: 20px;
}

.pwBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
}

.pwInputBox {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #BDC2C7;
    border-radius: 5px;
}

.pwInput {
    width: 100%;
    height: 100%;
    font-size: 13px;
    font-weight: 800;
    color: #8C8C8C;
    & input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        padding-left: 10px;
        font-size: 13px;
        font-weight: 600;
    }
    & input::placeholder {
        font-size: 13px;
        font-weight: 500;
    }
}

.pwMark {
    width: 20px;
    margin-right: 20px;
}

.pwCheck {
    width: 100%;
    height: 20px;
    margin-bottom: 15px;
    & span {
        margin-left: 15px;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #8C8C8C;
    }
}

.btnBox {
    width: 100%;
    height: 50px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        color: #FFF;
        font-size: 20px;
        font-weight: 600;
    }
}

.pwOkTxt1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
}

.pwOkTxt2 {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
}

.pwOkTxt3 {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.pwOkBtnBox {
    width: 100%;
    height: 50px;
    & a {
        text-decoration: none;
        display: inline-block;
        width: 456px;
        height: 50px;
        & button {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 5px;
            background-color: #00A0A0;
            color: #FFF;
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
    }
    
    }
}