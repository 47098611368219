.keywordFunnel {
    width: 870px;
    height: 343px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.keywordFunnelTitle {
    width: 827px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    & p {
        font-size: 20px;
        font-weight: 800;
    }
}

.keywordFunnelContent {
    width: 827px;
    height: 230px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.keywordFunnelChart {
    width: 391px;
    height: 212px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}

.keywordFunnelChartFirst5 {
    width: 391px;
    height: 69px;
    position: relative;
    background: rgba(0, 176, 80, 0.2);
    clip-path: polygon(0 0, 100% 0, 91% 100%, 9% 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.keywordFunnelChartFirst89 {
    width: 391px;
    height: 69px;
    position: relative;
    background: rgba(255, 180, 18, 0.2);
    clip-path: polygon(0 0, 100% 0, 91% 100%, 9% 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.keywordFunnelChartFirst100 {
    width: 391px;
    height: 69px;
    position: relative;
    background: rgba(0, 114, 240, 0.2);
    clip-path: polygon(0 0, 100% 0, 91% 100%, 9% 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chartFirst5Text1 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 15px;
    font-weight: 600;
}

.chartFirst5Text2 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 20px;
    font-weight: 600;
}

.chartFirst89Text1 {
    color: rgba(0, 0, 0, 0.5);
    font-size: 15px;
    font-weight: 600;
}

.chartFirst89Text2 {
    color: rgba(0, 0, 0, 0.5);
    font-size: 20px;
    font-weight: 600;
}

.chartFirst100Text1 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 15px;
    font-weight: 600;
}

.chartFirst100Text2 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 20px;
    font-weight: 600;
}

.keywordFunnelChartSecond5 {
    width: 320px;
    height: 69px;
    position: relative;
    background: rgba(0, 176, 80, 0.6);
    clip-path: polygon(0 0, 100% 0, 80.4% 100%, 19.6% 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.keywordFunnelChartSecond89 {
    width: 320px;
    height: 69px;
    position: relative;
    background: rgba(255, 180, 18, 0.6);
    clip-path: polygon(0 0, 100% 0, 80.4% 100%, 19.6% 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.keywordFunnelChartSecond100 {
    width: 320px;
    height: 69px;
    position: relative;
    background: rgba(0, 114, 240, 0.6);
    clip-path: polygon(0 0, 100% 0, 80.4% 100%, 19.6% 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.chartSecond5Text1 {
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
}

.chartSecond5Text2 {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}

.chartSecond89Text1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 15px;
    font-weight: 600;
}

.chartSecond89Text2 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 20px;
    font-weight: 600;
}

.chartSecond100Text1 {
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
}

.chartSecond100Text2 {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}

.keywordFunnelChartThird5 {
    width: 193px;
    height: 69px;
    background-color: #00B050;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.keywordFunnelChartThird89 {
    width: 193px;
    height: 69px;
    background-color: #FFB412;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.keywordFunnelChartThird100 {
    width: 193px;
    height: 69px;
    background-color: #0072F0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.chartThird5Text1 {
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
}

.chartThird5Text2 {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}

.chartThird89Text1 {
    color: #000;
    font-size: 15px;
    font-weight: 600;
}

.chartThird89Text2 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
}

.chartThird100Text1 {
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
}

.chartThird100Text2 {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}

.keywordFunnelDataBox {
    width: 392px;
    height: 230px;
    display: flex;
    flex-direction: column;
    gap: 8.5px;
}

.keywordFunnelDataItem {
    width: 392px;
    height: 71px;
    display: flex;
    gap: 10px;
    & div {
        width: 124px;
        height: 71px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
    }
}

.funnelImpressionData5 {
    border: 5px solid rgba(0, 176, 80, 0.2);
}

.funnelImpressionData89 {
    border: 5px solid rgba(255, 180, 18, 0.2);
}

.funnelImpressionData100 {
    border: 5px solid rgba(0, 114, 240, 0.2);
}

.funnelClickData5 {
    border: 5px solid rgba(0, 176, 80, 0.6);
}

.funnelClickData89 {
    border: 5px solid rgba(255, 180, 18, 0.6);
}

.funnelClickData100 {
    border: 5px solid rgba(0, 114, 240, 0.6);
}

.funnelConversionData5 {
    border: 5px solid rgba(0, 176, 80, 1);
}

.funnelConversionData89 {
    border: 5px solid rgba(255, 180, 18, 1);
}

.funnelConversionData100 {
    border: 5px solid rgba(0, 114, 240, 1);
}

.funnelDataName {
    color: #000;
    font-size: 13px;
    font-weight: 600;
}

.funnelDataValue {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.funnelPositive {
    color: #0048FF;
    font-size: 12px;
    font-weight: 600;
}

.funnelNegative {
    color: #FF0000;
    font-size: 12px;
    font-weight: 600;
}

.funnelZeroChange {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 800;
}
