.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    width: 572px;
    height: 800px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalCloseBtn {
    width: 474px;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.modalText {
    width: 474px;
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
}

.text1 {
    font-size: 15px;
    font-weight: 900;
    margin-bottom: 3px;
}

.text2 {
    font-size: 15px;
    font-weight: 600;
}

.stepBox {
    width: 490px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepTxtBox {
    width: 465px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.stepTxt1 {
    font-size: 14px;
    font-weight: 600;
}

.stepTxt2 {
    width: 204px;
    font-size: 14px;
    font-weight: 700;
    padding-left: 10px;
}

.infoInputBox {
    width: 373px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoTxt1 {
    width: 360px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}

.infotxt2 {
    width: 360px;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 20px;
}

.guideBtn {
    width: 373px;
    height: 64px;
    margin-bottom: 20px;
    & button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        & p {
            position: absolute;
            width: 323px;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 5px;
        }
    }
}

.propertyInputBox {
    width: 373px;
    height: 117px;
    background-color: #F4F6F8;
    margin-bottom: 10px;
    border-radius: 5px;
}

.propertyInputTxt {
    font-size: 15px;
    font-weight: 600;
    margin-top: 15px;
    margin-left: 15px;
}

.propertyInput {
    width: 340px;
    height: 32px;
    display: flex;
    gap: 19px;
    margin-left: 15px;
    margin-top: 10px;
    & input {
        width: 222px;
        border: 1px solid rgba(0,0,0,0.5);
        border-radius: 5px;
        padding-left: 10px;
        &::placeholder {
            font-size: 10px;
            font-weight: 500;
            color: rgba(0,0,0,0.5);
        }
    }
    & button {
        width: 99px;
        height: 32px;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        color: #FFF;
        font-size: 10px;
        font-weight: 600;
    }
}

.nameInputBox {
    width: 373px;
    height: 81px;
    background-color: #F4F6F8;
    border-radius: 5px;
}

.nameInputTxt {
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 10px;
}

.nameInput {
    width: 351px;
    height: 32px;
    display: flex;
    gap: 19px;
    margin-left: 10px;
    margin-top: 5px;
    & input {
        width: 351px;
        border: 1px solid rgba(0,0,0,0.5);
        border-radius: 5px;
        padding-left: 10px;
        &::placeholder {
    
            font-size: 10px;
            font-weight: 500;
            color: rgba(0,0,0,0.5);
        }
    }
}

.btnBox {
    width: 373px;
    height: 55px;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
}

.prevBtn {
    width: 168px;
    height: 55px;
    border: none;
    border-radius: 5px;
    background-color: #F4F6F8;
    color: #00A0A0;
    font-size: 20px;
    font-weight: 800;
}

.nextBtn {
    width: 168px;
    height: 55px;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    color: #FFF;
    font-size: 20px;
    font-weight: 800;
}