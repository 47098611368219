.memo {
    padding: 15px;
}

.memoSaveButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    & button {
        width: 92px;
        height: 28px;
        border-radius: 5px;
        border: none;
        background-color: #00A0A0;
        color: #fff;
        font-size: 15px;
        font-weight: 800;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        margin-right: 4px;
        margin-bottom: 15px;
    }
}