.table {
    max-width: 827px;
    height: 340px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        width: 6px; /* 스크롤바 너비 */
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* 트랙 배경색 */
        border-radius: 5px; /* 트랙 경계선 둥글게 */
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background: #00A0A0; /* 썸 배경색 */
        border-radius: 5px; /* 썸 경계선 둥글게 */
        height: 64px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #00A0A0; /* 썸 hover 시 배경색 */
    }
}

.funnelTable {
    width: 827px;
    height: 340px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        width: 6px; /* 스크롤바 너비 */
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* 트랙 배경색 */
        border-radius: 5px; /* 트랙 경계선 둥글게 */
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background: #00A0A0; /* 썸 배경색 */
        border-radius: 5px; /* 썸 경계선 둥글게 */
        height: 64px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #00A0A0; /* 썸 hover 시 배경색 */
    }
}

.creativeTable {
    width: 100%;
    height: 694px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        width: 6px; /* 스크롤바 너비 */
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* 트랙 배경색 */
        border-radius: 5px; /* 트랙 경계선 둥글게 */
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background: #00A0A0; /* 썸 배경색 */
        border-radius: 5px; /* 썸 경계선 둥글게 */
        height: 64px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #00A0A0; /* 썸 hover 시 배경색 */
    }
}

.tableHeader {
    width: fit-content;
    min-width: 827px;
    height: 27px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
}

.tableHeaderRow {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 27px;
    & div {
        flex: 1;
        min-width: 118px;
        max-width: 118px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 14px;
        font-weight: 700;
        &:last-child {
            border-right: none;
        }
    }
    & span {
        height: 14px;
        margin-left: 3px;
    }
}

.creativeHeaderRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 27px;
    & div {
        flex: 1;
        min-width: 118px;
        max-width: 118px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 14px;
        font-weight: 700;
        &:last-child {
            border-right: none;
        }
        &:first-child {
            flex: 0 0 18%;
        }
    }
    & span {
        height: 14px;
        margin-left: 3px;
    }
}

.tableBody {
    width: fit-content;
    min-width: 827px;
}

.tableBodyRow {
    width: 100%;
    height: 27px;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    & div {
        flex: 1;
        min-width: 118px;
        max-width: 118px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 27px;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 14px;
        font-weight: 600;
        position: relative;

        /* 텍스트가 넘칠 경우 ... 처리 */
        // overflow: hidden; /* 스크롤 대신 숨기기 */
        // text-overflow: ellipsis; /* ... 표시 */
        white-space: nowrap; /* 텍스트 줄바꿈 방지 */

        & p {
            margin: 0; /* 기본 여백 제거 */
            white-space: nowrap;
            overflow: hidden; /* 스크롤 대신 숨기기 */
            text-overflow: ellipsis;
        }

        &:hover .tooltipTxt {
            visibility: visible;
            opacity: 1;
        }

        .tooltipTxt {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
            background-color: #FFF;
            border: 1px solid rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            padding: 5px;
            transition: visibility 0.2s, opacity 0.2s;
        }
    }
    &:nth-child(odd) {
        background-color: #F6F7F6;
    }
}


.creativeBodyRow {
    width: 100%;
    height: 128px;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    & div {
        flex: 1;
        min-width: 118px;
        max-width: 118px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 128px;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 14px;
        font-weight: 600;
        flex-shrink: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        & p {
            white-space: nowrap;
            overflow-y: hidden;
            overflow-x: scroll; 
            scrollbar-width: none; 
            -ms-overflow-style: none; 
            &::-webkit-scrollbar { 
                display: none;
            }
            text-overflow: clip;
        }
        &:hover .tooltipTxt {
            visibility: visible;
            opacity: 1;
        }
        .tooltipTxt {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%); 
            z-index: 10;
            background-color: #FFF;
            border: 1px solid rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            padding: 5px;
            transition: visibility 0.2s, opacity 0.2s;
        }
        &:first-child {
            flex: 0 0 18%;
            display: flex;
            flex-direction: column;
        }
    }
    &:nth-child(odd) {
        background-color: #F6F7F6;
    }
    &:first-child {
        height: 27px;
        & div {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 27px;
            border-right: 1px solid rgba(0, 0, 0, 0.05);

            font-size: 14px;
            font-weight: 600;
            & p {
                white-space: nowrap;
                overflow-y: hidden;
                overflow-x: scroll;
                scrollbar-width: none;
                -ms-overflow-style: none; 
                &::-webkit-scrollbar {
                    display: none;
                }
                text-overflow: clip;
            }
            &:first-child {
                flex: 0 0 18%;
            }
            flex-shrink: 1;
            min-width: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.linkTxt {
    color: #0066EC;
    text-decoration: underline;
    cursor: pointer;
    &:not(:first-child) {
        text-decoration: none;
        color: #000;
        cursor: default;
    }
}

.creativeTxt {
    color: #0066EC;
    text-decoration: underline;
    & p {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        width: 144px;
    }
}

.firstLinkTxt {
    color: #000;
}