.recollectBox {
    width: 437px;
    // max-width: 445px;
    height: 455px;
    background-color: #FFF;
    border: 8px solid rgba(0,160,160,0.4);
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recollectTxt {
    width: 383px;
    height: 44px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    font-size: 20px;
    font-weight: 900;
    margin-top: 10px;
}

.recollectList {
    width: 377px;
    height: 320px;
    overflow-y: scroll;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-right: 5px;
}

.recollectList::-webkit-scrollbar {
    width: 4px; /* 스크롤바 너비 */
}

.recollectList::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙 배경색 */
    border-radius: 5px; /* 트랙 경계선 둥글게 */
    display: none;
}

.recollectList::-webkit-scrollbar-thumb {
    background: #00A0A0; /* 썸 배경색 */
    border-radius: 5px; /* 썸 경계선 둥글게 */
    height: 64px;
}

.recollectList::-webkit-scrollbar-thumb:hover {
    background: #00A0A0; /* 썸 hover 시 배경색 */
}

.listContent {
    width: 100%;
    height: 60px;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.contentImg {
    width: 12%;
    display: flex;
    justify-content: center;
}

.reportName {
    width: 45%;
    font-size: 16px;
    font-weight: 800;
}

.contentTxt {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    font-size: 10px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
}

.collectBtn {
    width: 68px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: #00A0A0;
    color: #FFF;
    font-size: 13px;
    font-weight: 800;
}

.recollectTxt2 {
    font-size: 10px;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
    border-bottom: 1px solid rgba(0,0,0,0.5);
}