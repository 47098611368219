.performance {
    width: 1320px;
    height: 1040px;
    border-radius: 10px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.performanceTxt {
    width: 1240px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & p {
        font-size: 20px;
        font-weight: 800;
    }
    margin-top: 30px;
    margin-bottom: 30px;
}

.PerformanceLine {
    width: 1140px;
    height: 360px;
    display: flex;
    flex-direction: column;
    // align-items: flex-end;
    gap: 15px;
    position: relative;
    margin-bottom: 25px;
}

.lineLabels {
    width: 1140px;
    display: flex;
    justify-content: flex-end;
}

.lineBorder {
    position: absolute;
    width: 125px;
    height: 289px;
    border: 3px dashed #00A0A0;
    border-radius: 10px;
    background-color: rgba(0, 160, 160, 0.15);
    pointer-events: none;
    right: 20px;
    margin-top: 38px;
}